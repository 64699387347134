<template>
  <b-card :title="tableTitle">
    <div class="mb-2">
      <!-- filter -->
      <b-col md="6">
        <slot name="filter"></slot>
      </b-col>
      <div class="d-flex justify-content-between flex-wrap">
        <div>
          <b-button
            v-if="creates == 'true'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="ml-0"
            @click="gotoCreatePage()"
          >
            Tambah {{ buttonTitle }}
          </b-button>
        </div>
        <!-- filter -->
        <b-form-group
          v-if="search == 'true'"
          label="Cari"
          label-cols-sm="2"
          label-align-sm="right"
          label-size=""
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="8">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="'Cari ' + buttonTitle"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Hapus
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tableDigirans"
    >
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(status_active)="data">
        {{
          data.item.account && data.item.account.verified == true
            ? "Verified"
            : "Not Verified"
        }}
      </template>

      <template #cell(companyType)="data">
        {{ data.item.companyType && data.item.companyType.name }}
      </template>

      <template #cell(companyTypeName)="data">
        {{ data.item.companyTypeName && data.item.companyTypeName.name }}
      </template>

      <template #cell(platformType)="data">
        {{ data.item.platformType && data.item.platformType.name }}
      </template>

      <template #cell(service)="data">
        {{ data.item.service && data.item.service.name }}
      </template>

      <template #cell(category)="data">
        {{ data.item.category && data.item.category.name }}
      </template>

      <template #cell(type)="data">
        <p class="text-capitalize"> {{ data.item.type }}</p> 
      </template>

      <template #cell(status)="data">
        <span class="text-capitalize">{{ data.item.status }}</span>
      </template>

      <!-- A virtual column -->
      <template #cell(Aksi)="data">
        <!-- <b-dropdown right text="Menu"> -->
        <!-- <b-dropdown-item
            v-if="verifiers == 'true'"
            @click="confirmVerified(data.item.accountID, data.item.account.verified)"
            >Verify</b-dropdown-item
          >
          <b-dropdown-item
            v-if="shows == 'true'"
            @click="gotoShowPage(data.item.id)"
            >Show</b-dropdown-item
          >
          <b-dropdown-item
            v-if="updates == 'true'"
            @click="gotoEditPage(data.item.id)"
            >Edit</b-dropdown-item
          > -->
        <!-- <b-dropdown-divider v-if="!data.item.is_admin"></b-dropdown-divider> -->
        <!-- <b-dropdown-item
            v-if="deletes == 'true'"
            variant="danger"
            @click="confirmText(data.item.id)"
            >Delete</b-dropdown-item
          >

        <b-dropdown-item
            v-if="confirmApproves == 'true' && data.item.broadcastStatus == false"
            variant="danger"
            @click="confirmApprove(data.item.id)"
            >Approve</b-dropdown-item
          >
        </b-dropdown> -->

        <div style="min-width:200px;">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Verifikasi'"
            class="btn-icon float-sm-left mr-1"
            size="sm"
            v-if="
              verifiers == 'true' &&
                data.item.account &&
                data.item.account.verified == false
            "
            @click="
              confirmVerified(data.item.account.id, data.item.account.verified)
            "
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Verifikasi'"
            class="btn-icon float-sm-left mr-1"
            size="sm"
            v-if="verifiersadv == 'true' && data.item.verifiedRequest == true"
            @click="
              confirmVerifiedAdvertiser(
                data.item.id,
                data.item.account.verified
              )
            "
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Verifikasi'"
            class="btn-icon float-sm-left mr-1"
            size="sm"
            v-if="
              verifiersadvrequest == 'true' &&
                data.item.account &&
                data.item.verifiedRequest == true
            "
            @click="
              confirmVerifiedAdvertiser(
                data.item.id,
                data.item.account.verified
              )
            "
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-popover.hover.bottom="'Verifikasi'"
            class="btn-icon float-sm-left mr-1"
            size="sm"
            v-if="confirmApproves == 'true' && data.item.status == 'draft'"
            @click="confirmApprove(data.item.id)"
          >
            <feather-icon icon="CheckCircleIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            v-b-popover.hover.bottom="'Lihat'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="shows == 'true'"
            @click="gotoShowPage(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Edit'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="updates == 'true'"
            @click="gotoEditPage(data.item.id)"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Hapus'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="deletes == 'true'"
            @click="confirmText(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>

          <div>
            <b-form-checkbox
              v-if="showandhide == 'true' && data.item.status == 'on-broadcast'"
              :checked="data.item.broadcastStatus == true"
              v-b-popover.hover.bottom="'Show / Hide'"
              class="custom-control-primary float-sm-left"
              name="check-button"
              @change="confirmShowHide(data.item.id, data.item.broadcastStatus)"
              switch
              style="margin-top:5px;"
            >
              <span class="switch-icon-left">
                <feather-icon icon="EyeIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="EyeOffIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div>
      </template>
    </b-table>
    <span v-if="totalRows == 1" style="margin: 1rem"></span>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
            <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            />
        </b-form-group> -->

      <b-form-group
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="onTableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <b-modal
      ref="my-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Verifikasi Influencer"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(verifiedHandling)">
          <ValidationProvider
            name="Verification Status"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Status" label-for="mc-verification">
              <b-form-radio-group
                v-model="model.verified"
                :options="verifiedOptions"
                class="mt-1"
                value-field="value"
                text-field="text"
              />

              <p class="fs-6 text-danger">
                {{ errors[0] }}
              </p>
            </b-form-group>
          </ValidationProvider>
          <b-form-group>
            <label for="textarea-auto-height">Note (Opsional)</label>
            <b-form-textarea
              id="textarea-auto-height"
              placeholder="Notes"
              rows="3"
              v-model="model.verification_notes"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-form-group>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 float-right"
          >
            Verifikasi
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            @click="hideModal()"
          >
            Batal
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-modal>

    <b-modal
      ref="my-modal-adv"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Verifikasi Advertiser"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(verifiedHandlingAdv)">
          <ValidationProvider
            name="Verification Status"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group
              label="Status Verification"
              label-for="mc-verification"
            >
              <b-form-radio-group
                v-model="model.verified"
                :options="verifiedOptions"
                class="mt-1"
                value-field="value"
                text-field="text"
              />

              <p class="fs-6 text-danger">
                {{ errors[0] }}
              </p>
            </b-form-group>
          </ValidationProvider>
          <b-form-group>
            <label for="textarea-auto-height">Note (Optional)</label>
            <b-form-textarea
              id="textarea-auto-height"
              placeholder="Notes"
              rows="3"
              v-model="model.verification_notes"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-form-group>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 float-right"
          >
            Verifikasi
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            @click="hideModalAdv()"
          >
            Batal
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </b-card>
</template>
<style></style>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BCol,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: [
    "tableTitle",
    "apiUrl",
    "perPage",
    "currentPage",
    "items",
    "fields",
    "totalRows",
    "apiBase",
    "creates",
    "updates",
    "deletes",
    "shows",
    "search",
    "verifiers",
    "verifiersadv",
    "confirmApproves",
    "showandhide",
    "buttonTitle",
    "verifiersadvrequest",
  ],
  components: {
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-popover": VBPopover,
  },
  data() {
    return {
      errors: {},
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      pageOptions: [1, 10, 25, 50, 100],
      filter: null,
      sortDirection: "asc",
      sortBy: "",
      sortDesc: false,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      verifiedOptions: [
        {
          value: false,
          text: "Not Verified",
        },
        {
          value: true,
          text: "Verified",
        },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      model: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    let _ = this;
    // Set the initial number of items
    EventBus.$on("changeValue", () => {
      _.$refs.tableDigirans.refresh();
    });
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
      this.model = {};
    },
    hideModalAdv() {
      this.$refs["my-modal-adv"].hide();
      this.model = {};
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems);
    },
    changePerPage() {
      console.log(this.onTablePerPage, "ini Ditable");
      this.$emit("changePerPage", this.onTablePerPage);
    },
    gotoEditPage(id) {
      let _ = this;
      window.location = "/" + _.$props.apiUrl + "s/" + id;
    },
    gotoShowPage(id) {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/show");
    },
    gotoCreatePage() {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/add");
    },
    confirmText(id) {
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menghapus " + this.buttonTitle + "?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/" + this.$props.apiBase + "/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Dihapus",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableDigirans.refresh();
            })
            .catch((err) => {
              this.$swal({
                icon: "Danger",
                title: "Tidak bisa di hapus",
                text: err.response.data.message,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        }
      });
    },

    confirmApprove(id) {
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk setujui campaign ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // console.log(this.$props.apiBase);
          // let data = {
          //   "broadcastStatus" : true,
          // }
          axios
            .put("/" + this.$props.apiBase + "/approve/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Approved!",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableDigirans.refresh();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    confirmVerified(id, status) {
      console.log(this.$props.apiBase);
      this.$refs["my-modal"].show();
      this.model.verified = status;
      this.model.id = id;
    },

    confirmVerifiedAdvertiser(id, status) {
      console.log(this.$props.apiBase);
      this.$refs["my-modal-adv"].show();
      this.model.verified = status;
      this.model.id = id;
    },

    confirmShowHide(id, status) {
      let data = {
        broadcastStatus: status == true ? false : true,
      };
      axios
        .put("/" + this.$props.apiBase + "/display/" + id, data)
        .then((response) => {
          this.$swal({
            icon: "success",
            title: "Status berhasil diubah!",
            text: response.data.data.messages,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.$refs.tableDigirans.refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    verifiedHandling() {
      let _ = this;
      if (_.model.verified != true) {
        this.$swal({
          // title: 'Error!',
          text: "Silahkan ubah status menjadi 'Verified' ",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      axios
        .put(_.$props.apiBase + "/verification/" + _.model.id, _.model)
        .then((res) => {
          this.hideModal();
          _.$toast({
            component: ToastificationContent,
            props: {
              title: "Status influencer berhasil diubah",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$refs.tableDigirans.refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    },

    verifiedHandlingAdv() {
      let _ = this;
      console.log(this.$props.apiBase);
      console.log(this.model);

      let data = {
        verified: _.model.verified,
        verification_notes: _.model.verification_notes,
      };

      axios
        .put(_.$props.apiBase + "/account/" + _.model.id, data)
        .then((res) => {
          console.log(res);
          this.hideModalAdv();
          _.$toast({
            component: ToastificationContent,
            props: {
              title: "Ubah Status Berhasil",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$refs.tableDigirans.refresh();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    onTableCurrentPage: function(newValue) {
      let _ = this;
      console.log(newValue, "newvalue");
      _.$emit("changeCurrentPage", newValue);
    },
    items: function(newValue) {
      console.log(newValue);
      _.totalRows = _.items.length;
    },
  },
};
</script>
