<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="6" class="text-left">
          <b-row>
            <b-col md="12">
              <h5>Informasi Saldo</h5>
            </b-col>
            <b-col md="6">
              <p>Kredit iklan</p>
            </b-col>
            <b-col md="6">
              <p>: {{ model.creditBalance | currency }}</p>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="6" class="text-right">
          <b-row>
            <b-col md="12">
              <h5>Status Akun</h5>
            </b-col>
            <b-col md="12">
              <p>
                {{
                  model.account.verified == true ? "Verified" : " Not Verified"
                }}
              </p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs pills>
        <b-tab title="Informasi Pengguna" active>
          <b-row class="mt-3">
            <b-col md="12" class="text-left">
              <h5>Informasi Pengguna</h5>
            </b-col>
            <b-col md="6" class="text-left">
              <b-row>
                <b-col md="6">
                  <p>Nama Depan</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail1.firstName }}</p>
                </b-col>
                <b-col md="6">
                  <p>Nama Belakang</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail1.lastName }}</p>
                </b-col>
                <b-col md="6">
                  <p>Jenis Kelamin</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail1.gender }}</p>
                </b-col>
                <b-col md="6">
                  <p>Alamat Email</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail1.account.email }}</p>
                </b-col>
                <b-col md="6">
                  <p>No.Handphone</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail1.account.phoneNumber }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Informasi Bisnis">
          <b-row class="mt-3">
            <b-col md="12" class="text-left">
              <h5>Informasi Bisnis</h5>
            </b-col>
            <b-col md="6" class="text-left">
              <b-row>
                <b-col md="6">
                  <p>Nama Bisnis</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail2.companyName }}<span v-if="model.account.verified == true"><b><feather-icon class="ml-3" width="20px" height="20px;" icon="CheckCircleIcon" /></b></span></p>
                </b-col>
                <b-col md="6">
                  <p>Website Bisnis</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail2.companySite }}</p>
                </b-col>
                <b-col md="6">
                  <p>Tipe Kepemilikan Usaha</p>
                </b-col>
                <b-col md="6">
                  <p>
                    :
                    {{
                      detail2.ownershipType != null
                        ? detail2.ownershipType.name
                        : "-"
                    }}
                  </p>
                </b-col>
                <b-col md="6">
                  <p>Nomor NPWP</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ npwp_display }}</p>
                </b-col>
                <b-col md="6">
                  <p>Provinsi</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail2.province }}</p>
                </b-col>
                <b-col md="6">
                  <p>Kota/Kabupaten</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail2.city }}</p>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <b-row>
                <b-col md="6">
                  <p>Alamat Bisnis</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ detail2.address }}</p>
                </b-col>
                <b-col md="6">
                  <p>Jenis Usaha</p>
                </b-col>
                <b-col md="6">
                  <p>
                    :
                    {{
                      detail2.companyType != null
                        ? detail2.companyType.name
                        : "-"
                    }}
                  </p>
                </b-col>
                <b-col md="6">
                  <p>Nama Usaha</p>
                </b-col>
                <b-col md="6">
                  <p>
                    :
                    {{
                      detail2.ownershipType != null
                        ? detail2.companyTypeName.name
                        : "-"
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Informasi Proyek">
          <b-row class="mt-3">
            <b-col md="12" class="text-left">
              <h5>Informasi Proyek</h5>
            </b-col>
            <b-col md="6" class="text-left">
              <b-row v-for="(i, index) in detail3" :key="index">
                <b-col md="6">
                  <p>{{i.name}}</p>
                </b-col>
                <b-col md="6">
                  <p>: {{i.total}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'advertisers' }"
      >
        Kembali
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {mask} from 'vue-the-mask'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    mask
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      config: {
        api: "digirans/advertiser-managements/saldo",
        redirect: "advertisers",
        detail: "digirans/advertiser-managements",
        detail1: "digirans/advertiser-managements/user-info",
        detail2: "digirans/advertiser-managements/business-info",
        detail3: "digirans/advertiser-managements/project-info",
      },
      model: {
        account: {
          verified: null,
        },
      },
      detail1: {
        account: {
          email: null,
          phoneNumber: null,
        },
      },
      detail2: {},
      detail3: {},
      npwp_display:null
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getDetail1();
    _.getDetail2();
    _.getDetail3();
  },
  methods: {
    getData() {
      let _ = this;
      axios
        .get(_.config.detail + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.model = response.data.data;
        });
    },
    getDetail1() {
      let _ = this;
      axios
        .get(_.config.detail1 + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.detail1 = response.data.data;
        });
    },

    getDetail2() {
      let _ = this;
      axios
        .get(_.config.detail2 + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.detail2 = response.data.data;
          let spliting = _.detail2.npwp
          if (spliting != null && spliting != "") {
            let s  = spliting.toString()
            let res = s[0]+s[1]+'.'+s[2]+s[3]+s[4]+'.'+ s[5]+s[6]+s[7]+'.'+s[8]+'-'+s[9]+s[10]+s[11]+'.'+s[12]+s[13]+s[14]
            _.npwp_display = res    
          }else{
            _.npwp_display = "-"
          }
          
        });
    },

    getDetail3() {
      let _ = this;
      axios
        .get(_.config.detail3 + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.detail3 = response.data.data;
          console.log(_.detail3);
        });
    },
  },
};
</script>
