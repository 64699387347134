<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <div class="d-flex justify-content-end flex-wrap">
        <b-col md="4" class="justify-content-end">
          <b-form-group label="Filter" label-for="mc-type-company">
            <select class="form-control" v-model="filteringReview">
              <option v-for="(i, index) in options" :key="index" :value="i.text">
                {{ i.name }}
              </option>
            </select>
          </b-form-group>
        </b-col>
      </div>
      <b-tabs pills>
        <b-tab title="Instagram"  @click="changeUrl(1)" active>
          <div v-if="model.length > 0">
            <b-row
              v-for="(i, index) in model" :key="index"
              style="border: 2px solid #e83e8c; border-radius: 10px"
              class="mt-2 ml-1 mr-1"
            >
              <b-col md="2" class="mt-1 mb-1">
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <!-- <b-link>
                      <b-img
                        ref="previewEl"
                        rounded="circle"
                        src="https://picsum.photos/1024/400/?image=41"
                        class=""
                        height="65"
                        width="65"
                      />
                    </b-link> -->
                    <b-avatar :src="i.advertiser && i.advertiser.account.avatar ? i.advertiser.account.avatar : null" class="mb-1" size="90px"></b-avatar>
                  </b-media-aside>
                </b-media>
                <!--/ media  :src="'https://api-digirans.demoapp.xyz/' + i.campaign.banner" -->
              </b-col>
              <b-col md="8" class="text-left mt-1">
                <b-row>
                  <b-col md="12">
                    <h5 class="mb-0"><b>{{i.advertiser ? i.advertiser.companyName : '-'}}</b></h5>
                  </b-col>
                  <b-col md="12">
                    <b-form-rating
                      no-border
                      v-model="i.rating"
                      readonly
                      variant="primary"
                      inline
                      size="sm"
                      precision="2"
                      style="margin-left: -20px; font-size: 20px"
                    />
                  </b-col>
                  <b-col>
                    <p>{{i.message}}</p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
          <div v-else>
          <b-row>
            <b-col md=12>
              <h5 class="text-center">Belum ada ulasan</h5>
            </b-col>
          </b-row>
          
        </div>  
          <b-row>            
            <b-col md="12" v-show="allData > 5">
               <b-card class="text-right">
                 <a href="#" @click="showExtra = !showExtra" class="card-link">{{tagtext}}</a>
                </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Facebook"  @click="changeUrl(2)">
            <div v-if="model.length > 0">
              <b-row
                v-for="(i, index) in model" :key="index"
                style="border: 2px solid #e83e8c; border-radius: 10px"
                class="mt-2 ml-1 mr-1"
              >
                <b-col md="2" class="mt-1 mb-1">
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <!-- <b-link>
                        <b-img
                          ref="previewEl"
                          rounded="circle"
                          src="https://picsum.photos/1024/400/?image=41"
                          height="65"
                          width="65"
                        />
                      </b-link> -->
                      <b-avatar :src="i.advertiser && i.advertiser.account.avatar ? i.advertiser.account.avatar : null" class="mb-1" size="90px"></b-avatar>
                    </b-media-aside>
                  </b-media>
                  <!--/ media -->
                </b-col>
                <b-col md="8" class="text-left mt-1">
                  <b-row>
                    <b-col md="12">
                      <h5 class="mb-0"><b>{{i.advertiser ? i.advertiser.companyName : '-'}}</b></h5>
                    </b-col>
                    <b-col md="12">
                      <b-form-rating
                        no-border
                        v-model="i.rating"
                        readonly
                        variant="primary"
                        inline
                        size="sm"
                        precision="2"
                        style="margin-left: -20px; font-size: 20px"
                      />
                    </b-col>
                    <b-col>
                      <p>{{i.message}}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>  
            </div>
             <div v-else>
                <b-row>
                  <b-col md=12>
                    <h5 class="text-center">Belum ada ulasan</h5>
                  </b-col>
                </b-row>
                
              </div>  
          <b-row>            
            <b-col md="12" v-show="allData > 5">
               <b-card class="text-right">
                 <a href="#" @click="showExtra = !showExtra" class="card-link">{{tagtext}}</a>
                </b-card>
            </b-col>
          </b-row>
         </b-tab>
        <b-tab title="Youtube"  @click="changeUrl(3)"> 
            <div v-if="model.length > 0">
              <b-row
                v-for="(i, index) in model" :key="index"
                style="border: 2px solid #e83e8c; border-radius: 10px"
                class="mt-2 ml-1 mr-1"
              >
                <b-col md="2" class="mt-1 mb-1">
                  <!-- media -->
                  <b-media no-body>
                    <b-media-aside>
                      <!-- <b-link>
                        <b-img
                          ref="previewEl"
                          rounded="circle"
                          src="https://picsum.photos/1024/400/?image=41"
                          height="65"
                          width="65"
                        />
                      </b-link> -->
                      <!-- <b-avatar :src="model.account.avatar" class="mb-1" size="90px"></b-avatar> -->
                      <b-avatar :src="i.advertiser && i.advertiser.account.avatar ? i.advertiser.account.avatar : null" class="mb-1" size="90px"></b-avatar>
                    </b-media-aside>
                  </b-media>
                  <!--/ media -->
                </b-col>
                <b-col md="8" class="text-left mt-1">
                  <b-row>
                    <b-col md="12">
                      <h5 class="mb-0"><b>{{i.advertiser ? i.advertiser.companyName : '-'}}</b></h5>
                    </b-col>
                    <b-col md="12">
                      <b-form-rating
                        no-border
                        v-model="i.rating"
                        readonly
                        variant="primary"
                        inline
                        size="sm"
                        precision="2"
                        style="margin-left: -20px; font-size: 20px"
                      />
                    </b-col>
                    <b-col>
                      <p>{{i.message}}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
            <div v-else>
                <b-row>
                  <b-col md=12>
                    <h5 class="text-center">Belum ada ulasan</h5>
                  </b-col>
                </b-row>
                
            </div>  
          <b-row>            
            <b-col md="12" v-show="allData > 5">
               <b-card class="text-right">
                 <a href="#" @click="showExtra = !showExtra" class="card-link">{{tagtext}}</a>
                </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ path: '/influencers/' +  this.$route.params.id + '/show' }"
      >
        Kembali
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BFormRating,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    BFormRating,
    BAvatar,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      showExtra:false,
      tagtext:"Lihat lebih banyak",
      name: "InfluencerForms",
      limit:5,
      errors: {},
      config: {
        api: "",
        redirect: "influencers",
      },
      table_active: 1,
      allData:0,
      model: {},
      filteringReview: "Terbaru",
      rating: 4.5,
      options: [
        { id: 1, name: "Terbaru", text:"Terbaru"},
        { id: 2, name: "Rating Tertinggi", text:"Tertinggi" },
        { id: 3, name: "Rating Terendah", text:"Terendah" },
      ],
    };
  },
  mounted() {
    let _ = this;
    this.config.api = "digirans/influencer-managements/review/" + this.$route.params.id + "?socmedType=Instagram" + "&limit=5&filter=" + this.filteringReview
    _.getData();
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api).then((response) => {
        let _ = this;
        _.model = response.data.data.data;
        _.allData = response.data.data.totalData
      });
    },

    changeUrl(val) {
      this.table_active = val;
      if (val == 1) {
        this.limit = 5;
        this.showExtra = false;
        this.config.api = "digirans/influencer-managements/review/" + this.$route.params.id + "?socmedType=Instagram" + "&limit=" + this.limit + "&filter=" + this.filteringReview;
        this.getData()
      } else if (val == 2) {
        this.limit = 5;
        this.showExtra = false;
        this.config.api = "digirans/influencer-managements/review/" + this.$route.params.id + "?socmedType=Facebook" + "&limit=" + this.limit + "&filter=" + this.filteringReview;
        this.getData()
      } else {
        this.limit = 5;
        this.showExtra = false;
        this.config.api = "digirans/influencer-managements/review/" + this.$route.params.id + "?socmedType=YouTube" + "&limit=" + this.limit + "&filter=" + this.filteringReview;
        this.getData()
      }
    },
  },

  watch: {
    showExtra: function (newVal, oldVal) {
      if (newVal == true) {
        this.tagtext = "Lihat lebih sedikit"
        let _ = this;
        _.limit = _.allData
        let result = _.config.api.replaceAll("limit=5", "limit=" + _.limit);
        axios.get(result).then((response) => {
          let _ = this;
          _.model = response.data.data.data;
          _.allData = response.data.data.totalData
        });
      } else {
        this.tagtext = "Lihat lebih banyak"
        let _ = this;
        _.limit = _.allData
        let result = _.config.api.replaceAll("limit=" + _.limit, "limit=5");
        axios.get(result).then((response) => {
          let _ = this;
          _.model = response.data.data.data;
          _.allData = response.data.data.totalData
        });
      }
    },
    filteringReview: function (newVal, oldVal) {
      let _ = this
       // console.log(newVal, oldVal);
       _.config.api = _.config.api.replaceAll("filter=" + oldVal, "filter=" + newVal) 
        console.log(_.config.api);
       this.getData();
    }
  },

  // watch: {
  //   filteringReview: function (newVal, oldVal) {
  //     let _ = this
  //     // console.log(newVal, oldVal);
  //     _.config.api = _.config.api.replaceAll("filter=" + oldVal, "filter=" + newVal) 
  //     console.log(_.config.api);
  //     this.getData();
  //   }
  // },
};
</script>
