<template>
  <b-row>
    <b-col cols="12">
      <table-digirans-specified
        :tableTitle="this.$route.meta.title"
        apiUrl="topup-histories"
        apiBase="digirans/topup/histories"
        search="false"
        approving="false"
        shows="false"
        dollar="false"
        buttonTitle="Riwayat Pembayaran Withdraw"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
        <template v-slot:filter>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            @click="filter()"
          >
            Filter
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mr-1"
            @click="reseting_filter()"
          >
            Reset Filter
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class=""
            @click="Exporting()"
          >
            Export Excel
          </b-button>
        </template>
      </table-digirans-specified>
    </b-col>
    <b-modal
      ref="my-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Filter Riwayat Transaksi"
      size="lg"
    >
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-group label="Advertiser" label-for="mc-advertiser">
            <b-form-input
              id="mc-advertiser"
              placeholder="Advertiser"
              v-model="filtering.companyName"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Tanggal Transaksi" label-for="mc-date">
            <date-picker
              :firstDayOfWeek="1"
              v-model="filtering.createdAt"
              name="tanggal_transaksi"
              :lang="lang"
              :format="formats"
              :disabled-date="notAfterToday"
            ></date-picker>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Nominal Top Up" label-for="mc-topup">
            <b-form-input
              id="mc-topup"
              placeholder="Nominal Top Up"
              v-model="filtering.amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Status" label-for="mc-status">
            <select class="form-control" v-model="filtering.status">
              <option v-for="(i, index) in options" :key="index" :value="i.value">
                {{ i.name }}
              </option>
            </select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="filters()"
        class="mr-1 float-right"
      >
        Filter
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-secondary"
        @click="hideModal()"
      >
        Batal
      </b-button>
    </b-modal>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import { BRow, BCol, BButton, BFormGroup, BFormInput } from "bootstrap-vue";
import TableDigiransSpecified from "@/views/table/bs-table/TableDigiransSpecified";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "UsersList",
  components: {
    BRow,
    BCol,
    BButton,
    TableDigiransSpecified,
    BButton,
    BFormGroup,
    BFormInput,
    DatePicker,
  },
  data() {
    return {
      config: {
        api: "digirans/topup/histories",
        export: "digirans/topup/export?"
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "advertiser.companyName", label: "Advertiser",sortable: true},
        { key: "createdAt_cast", label: "Tanggal Transaksi" ,sortable: true},
        { key: "amount_cast", label: "Nominal" ,sortable: true},
        { key: "status", label: "Status" ,sortable: true},
        // { key: "Aksi", thClass: "width160px" },
      ],
      items: [],
      filtering: {
        companyName: "",
        amount: "",
        status: "",
        createdAt: "",
      },
      options: [
        {
          id: 1,
          name: "Paid",
          value: "paid",
        },
        {
          id: 2,
          name: "Pending",
          value: "pending",
        },
        {
          id: 3,
          name: "Canceled",
          value: "canceled",
        },
      ],
      filtering_export : {},
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    filter() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.model = {};
    },
    Exporting() {
      let _ = this;
      let ctx = this.addctx
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let url = this.config.export + filter + "&filtering="+ JSON.stringify(this.filtering_export)
      axios.get(url, { responseType: "arraybuffer" }).then((response) => {
        if (response) {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "RiwayatTopUp" + new Date();
          link.click();
        }
      });
    },
    getItems(ctx, callback) {
      console.log(ctx);
      const _ = this;
      this.addctx = ctx;
      this.addcall = callback;
      let filter = ctx.filter ? "&name=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? 'ASC' : 'DESC'
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length-1]
      if (sortBy.includes("_cast") == true) {
          sortBy = sortBy.split('_')
          sortBy = sortBy[0]
      }
      console.log(filter);
      axios
        .get(
          _.config.api +
            "?size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&filtering="+ JSON.stringify(this.filtering)+
            "&sortBy="+ sortBy + 
            "&orderBy="+ orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          // _.perPage = consume.perPage
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    reseting_filter()
    {
      this.getItems(this.addctx, this.addcall);
      this.filtering = {
        companyName: "",
        amount: "",
        status: "",
        createdAt: "",
      };

      this.filtering_export = {
        companyName: "",
        amount: "",
        status: "",
        createdAt: "",
      };
    },
    filters() {
      this.getItems(this.addctx, this.addcall);
      this.$refs["my-modal"].hide();
      this.filtering_export = this.filtering
    },
    renderTable(perPage, currentPage) {
      console.log(perPage, currentPage, "log");
      let a = currentPage;

      let _ = this;
      axios
        .get(_.config.api + "?size=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          _.perPage = 10;
          // _.perPage = consume.perPage
          _.items = consume.data;

          console.log(_.totalRows, _.currentPage, _.perPage, "ininini");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>