<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-alert
        v-height-fade
        dismissible
        v-model="showDismissibleAlert"
        fade
        class="mb-2"
        variant="danger"
        v-id="showDismissibleAlert == true"
      >
        <div class="alert-body">
          <span>{{ messages }}</span>
        </div>
      </b-alert>
      <b-row>
        <b-col md="6">
          <div>
            <b-row>
              <b-col md="6">
                <h5 class="mb-1">Invoice Tagihan</h5>
              </b-col>
              <b-col md="6">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="btn-icon float-sm-right"
                  v-if="model.status == 'on-process'"
                  @click="UploadInvoice()"
                >
                  Upload Invoice
                </b-button>
              </b-col>
            </b-row>
          </div>
          <b-table
            striped
            hover
            show-empty
            responsive
            :items="invoices"
            :fields="fields"
            class="mb-0"
          >
            <template #cell(ceratedAt)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <template #cell(filename)="data">
              <!-- @click="DowmloadFile(data.item.id) -->
              <b-link @click="DowmloadFile(data.item.id, 'invoice')">
                <span style="color: blue">{{ data.value }}</span>
              </b-link>
            </template>
          </b-table>
        </b-col>
        <b-col md="6">
          <div>
            <h5 class="mb-1">Bukti Pembayaran</h5>
          </div>
          <b-table
            striped
            hover
            responsive
            show-empty
            :items="receipts"
            :fields="fields2"
            class="mb-0"
          >
            <template #cell(ceratedAt)="data">
              <span class="text-nowrap">
                {{ data.value }}
              </span>
            </template>

            <template #cell(filename)="data">
              <!-- @click="DowmloadFile(data.item.id)" -->
              <b-link @click="DowmloadFile(data.item.id, 'receipt')">
                <span style="color: blue">{{ data.value }}</span>
              </b-link>
            </template>

            <template #cell(Aksi)="data">
              <div style="min-width: 70px">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  v-b-popover.hover.bottom="'Download'"
                  size="sm"
                  class="btn-icon float-sm-left mr-1"
                  v-if="data.item.filename != null"
                  @click="DowmloadFile(data.item.id, 'receipt')"
                >
                  <feather-icon icon="DownloadIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  v-b-popover.hover.bottom="'Setujui'"
                  size="sm"
                  class="btn-icon float-sm-left"
                  v-if="data.item.paid == false && data.item.filename != null"
                  @click="approving(data.item.id)"
                >
                  <feather-icon icon="CheckCircleIcon" />
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col md="12" class="mt-2">
          <h5 class="mb-1">Informasi Termin</h5>
          <b-row>
            <b-col md="3" class="mb-1">
              <h6
                class="text-center p-1 text-capitalize"
                style="border: 2px solid #e83e8c; border-radius: 2px"
              >
                {{ model.status }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="text-left">
              <b-row>
                <b-col md="6">
                  <p>Nama Bisnis</p>
                </b-col>
                <b-col md="6">
                  : {{ model.advertiser ? model.advertiser.companyName : "-" }}
                </b-col>
                <b-col md="6">
                  <p>Nama Campaign</p>
                </b-col>
                <b-col md="6">
                  : {{ model.campaign ? model.campaign.name : "-" }}
                </b-col>
                <b-col md="6">
                  <p>Batas Akhir Pembayaran</p>
                </b-col>
                <b-col md="6"> : {{ model.endDate_cast }} </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6">
                  <p>Nama Pengguna</p>
                </b-col>
                <b-col md="6">
                  : {{ model.advertiser ? model.advertiser.fullName : "-" }}
                </b-col>
                <b-col md="6">
                  <p>Tanggal Invoice</p>
                </b-col>
                <b-col md="6"> : {{ model.createdAt_cast }} </b-col>
                <b-col md="6">
                  <p>Waktu Termin</p>
                </b-col>
                <b-col md="6"> : {{ model.amount }} </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card> </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'termin-payments' }"
      >
        Kembali
      </b-button>
    </b-card-footer>
    <b-modal
      ref="my-modal-process"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      title="Proses Withdraw"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(uploading)">
          <b-row>
            <b-col md="12" class="mb-1">
              <b-form-group
                label="Upload Dokumen"
                label-for="mc-upload-dokumen"
              >
                <input
                  type="file"
                  class="form-control"
                  @change="uploadDocuments"
                />
                <p v-if="error_show == true" class="fs-6 text-danger mb-1">
                  File harud diisi
                </p>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class=""
                style="width: 100%"
              >
                Upload
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BTable,
  VBPopover,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    BTable,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-popover": VBPopover,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      showDismissibleAlert: false,
      errors: {},
      config: {
        api: "digirans/termin-payments",
        file: "digirans/termin-payments/invoice",
        redirect: "termin-payments",
        put_data: "digirans/termin-payments/invoice/upload/",
      },
      attachment: null,
      model: {
        account: {},
      },
      table_active: 1,
      fields: [
        { key: "createdAt", label: "Tanggal" },
        { key: "invoiceNo", label: "Nomor Invoice" },
        { key: "filename", label: "File" },
      ],
      fields2: [
        { key: "createdAt", label: "Tanggal" },
        { key: "filename", label: "File" },
        { key: "Aksi", thClass: "width60px" },
      ],
      error_show: false,
      invoices: [],
      receipts: [],
      messages: "",
      items: [
        {
          id: 1,
          Tanggal: "2021-02-01",
          File: "invoicesample.pdf",
        },
      ],
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
    _.getInvoice();
  },
  methods: {
    uploadDocuments(e) {
      let vm = this;
      this.attachment = e.target.files[0];
      console.log(this.attachment);
    },
    uploading() {
      let _ = this;
      if (this.attachment == null) {
        this.error_show = true;
        return;
      }

      let formData = new FormData();
      formData.append("attachment", this.attachment);
      axios
        .put(_.config.put_data + _.$route.params.id, formData)
        .then((res) => {
          this.$refs["my-modal-process"].hide();
          _.getInvoice();
          _.getData();
        })
        .catch((e) => {
          let vm = this;
          this.$refs["my-modal-process"].hide();
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
    UploadInvoice() {
      this.$refs["my-modal-process"].show();
    },

    DowmloadFile(id, type) {
      console.log(id, type);
      let url = "digirans/termin-payments/download/" + type + "/" + id;
      axios.get(url).then((response) => {
        if (response) {
          // let blob = new Blob([response.data], {
          //   type: "application/pdf",
          // });
          // let link = document.createElement("a");
          // link.href = window.URL.createObjectURL(blob);
          // link.download = "File Receipt Payment " + id + new Date();
          // link.click();
          window.open(response.data);
        }
      });
    },

    approving(id) {
      let _ = this;
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menyetujui bukti pembayaran ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .put("/digirans/termin-payments/invoice/approve/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Bukti Pembayaran Disetujui",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              _.getInvoice();
              _.getData();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data.data;
      });
    },
    getInvoice() {
      let _ = this;
      axios
        .get(_.config.file + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.invoices = response.data.data.invoices;
          console.log(_.invoices);
          _.receipts = response.data.data.receipts;
        });
    },
  },
};
</script>
