<template>
  <b-card>
    <b-tabs pills>
      <b-tab title="Informasi Saldo" @click="changeUrl(1)" active>
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  name="Kredit Iklan"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Kredit Iklan" label-for="mc-Kredit Iklan">
                    <!-- <b-form-input
                      id="mc-Kredit Iklan"
                      placeholder="Kredit Iklan"
                      v-model="model.creditBalance"
                    /> -->

                     <vue-numeric class="form-control" v-bind:minus="false" v-bind:precision="2" currency="Rp." separator="." v-model="model.creditBalance"></vue-numeric>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'advertisers' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Informasi Pengguna" @click="changeUrl(2)">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Depan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Depan" label-for="mc-first-name">
                    <b-form-input
                      id="mc-first-name"
                      placeholder="Masukkan Nama Depan"
                      v-model="model.firstName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Belakang"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Belakang" label-for="mc-last-name">
                    <b-form-input
                      id="mc-last-name"
                      placeholder="Masukkan Nama Belakang"
                      v-model="model.lastName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Jenis Kelamin"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Jenis Kelamin" label-for="mc-gender">
                    <b-form-radio-group
                      v-model="model.gender"
                      :options="genderOptions"
                      class="mt-1"
                      value-field="value"
                      text-field="text"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Tanggal Lahir"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Tanggal Lahir" label-for="mc-date-birth">
                    <date-picker
                      :firstDayOfWeek="1"
                      v-model="model.dateOfBirth"
                      name="date_of_birth"
                      :lang="lang"
                      :disabled-date="notAfterToday"
                      :format=formats
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Alamat Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Alamat Email" label-for="mc-email">
                    <b-form-input
                      id="mc-email"
                      placeholder="Masukkan Alamat Email"
                      v-model="model.email"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nomor Telepon"
                  :rules="{ regex:/^(\+62|62|0)8[1-9][0-9]{6,11}$/, required:true}"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nomor Telepon" label-for="mc-no-telepon">
                    <b-form-input
                      id="mc-no-telepon"
                      placeholder="Masukkan Nomor Telepon"
                      v-model="model.phoneNumber"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'advertisers' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Informasi Bisnis" @click="changeUrl(3)">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Bisnis"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Bisnis" label-for="mc-company-name">
                    <b-form-input
                      id="mc-company-name"
                      placeholder="Masukkan Nama Bisnis"
                      v-model="model.companyName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Website Bisnis"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Website Bisnis (Opsional)"
                    label-for="mc-web-bisnis"
                  >
                    <b-form-input
                      id="mc-web-bisnis"
                      placeholder="Masukkan Website Bisnis"
                      v-model="model.companySite"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Tipe Kepimilikan Usaha"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Tipe Kepemilikan Usaha"
                    label-for="mc-type-company"
                  >
                    <select class="form-control" v-model="model.ownershipTypeID">
                      <option
                        v-for="(i, index) in option_type_owner"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="NPWP Perusahaan"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Nomor NPWP"
                    label-for="mc-npwp-perusahaan"
                  >
                    <b-form-input
                      id="mc-npwp-perusahaan"
                      placeholder="Masukkan Nomor NPWP"
                      v-model="model.npwp"
                      v-mask="'##.###.###.#-###.###'"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Provinsi"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Provinsi" label-for="mc-provinsi">
                    <select class="form-control" v-model="model.province" @change="getProvinceId()">
                      <option
                        v-for="(i, index) in options_province"
                        :key="index"
                        :value="i.name"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Kota"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Kota/Kabupaten" label-for="mc-kota">
                    <select class="form-control" v-model="model.city">
                      <option
                        v-for="(i, index) in options_city"
                        :key="index"
                        :value="i.name"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="12">
                <label for="textarea-auto-height">Alamat</label>
                <ValidationProvider
                  name="Alamat"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-textarea
                    id="textarea-auto-height"
                    placeholder="Masukkan Alamat"
                    rows="3"
                    v-model="model.address"
                    max-rows="8"
                    class="mb-1 mb-xl-0"
                  />
                   <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Jenis Usaha"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Jenis Usaha" label-for="mc-type-company">
                    <select class="form-control" v-model="model.companyTypeID">
                      <option
                        v-for="(i, index) in option_types"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Jenis Usaha"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Nama Jenis Usaha"
                    label-for="mc-name-company"
                  >
                    <select class="form-control" v-model="model.companyTypeNameID">
                      <option
                        v-for="(i, index) in option_names"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'advertisers' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Informasi Akun   " @click="changeUrl(4)">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <ValidationProvider
            name="Verifikasi Status"
            rules="required"
            v-slot="{ errors }"
        >
        <b-form-group label="Verifikasi Status" label-for="mc-verification">
          <b-form-radio-group
            v-model="model.verified"
            :options="verifiedOptions"
            class="mt-1"
            value-field="value"
            text-field="text"
          />

        <p class="fs-6 text-danger">
            {{ errors[0] }}
        </p>
        </b-form-group>
        </ValidationProvider>
        <b-form-group>
          <label for="textarea-auto-height">Note (Optional)</label>
          <b-form-textarea
            id="textarea-auto-height"
            placeholder="Notes"
            rows="3"
            v-model="model.verification_notes"
            max-rows="8"
            class="mb-1 mb-xl-0"
          />
        </b-form-group>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1 float-right"
        >
          Simpan
        </b-button>
        <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'advertisers' }"
              >
                Batal
              </b-button>
          </b-form>
        </ValidationObserver>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueNumeric from 'vue-numeric'
import {mask} from 'vue-the-mask'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    VueNumeric
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    mask
  },
  data() {
    return {
      options_city: [],
      options_province: [],
      name: "InfluencerForms",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "digirans/advertiser-managements/saldo",
        redirect: "advertisers",
        detail: "digirans/advertiser-managements",
        type: "misc/types/company-types",
        names: "misc/types/company-type-names",
        province: "misc/types/provinces",
        city: "misc/types/city",
        owner: "misc/types/ownership-types",
      },
      model: {
        companySite:""
      },
      messages: "",
      genderOptions: [
        { text: "Laki-Laki", value: "Laki-Laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      dir: "ltr",
      verifiedOptions: [
        {
          value:false ,
          text: "Not Verified",
        },
        {
          value: true,
          text: "Verified",
        },
      ],
      option_types: [],
      option_names: [],
      option_type_owner: [],
      selected: [{ title: "Square" }, { title: "Romboid" }],
      socials: [],
      provId:null,
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData()
    _.getProvince();
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    changeUrl(val) {
      this.table_active = val;
      if (val == 1) {
        this.config.api = "digirans/advertiser-managements/saldo";
        this.config.detail = "digirans/advertiser-managements";
        this.getData();
      } else if (val == 2) {
        this.config.api = "digirans/advertiser-managements/user";
        this.config.detail = "digirans/advertiser-managements/user-info";
        this.getData();
      } else if (val == 3) {
        this.config.api = "digirans/advertiser-managements/business";
        this.config.detail = "digirans/advertiser-managements/business-info";
        this.getData();
        this.getType();
        this.getNames();
        this.getOwnerType();
      } else {
        this.config.api = "digirans/advertiser-managements/account";
        this.config.detail = "digirans/advertiser-managements";
        this.getData();
      }
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.detail + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;

            if (_.table_active == 2) {
              var str = _.model.dateOfBirth;
              var res = str.split("-");

              // console.log(res[res.length - 1], res[res.length - 2], res[res.length - 3]);

              _.model.dateOfBirth = new Date(
                res[res.length - 1],
                res[res.length - 2] - 1,
                res[res.length - 3]
              );

              // console.log(_.model.dateOfBirth);
              
              _.model.email = _.model.account.email;
              _.model.phoneNumber = _.model.account.phoneNumber;
            }

            if (_.table_active == 4) {
                this.model.verified = _.model.account.verified 
            }

            if (_.table_active == 3) {
                let tempProvince = this.options_province;
                tempProvince = tempProvince.filter((item) => {
                  return item.name == _.model.province;
                });
                this.provId = tempProvince[0].id

                _.model.companyTypeName =_.model.companyTypeName.name;
            }
          });
      }
    },
    getProvinceId()
    {
      let _ = this
      this.model.city = null
      let tempProvince = this.options_province;
      tempProvince = tempProvince.filter((item) => {
        return item.name == _.model.province;
      });
      this.provId = tempProvince[0].id
    },  
    getType() {
      let _ = this;
      axios.get(_.config.type).then((response) => {
        let _ = this;
        _.option_types = response.data.data;
      });
    },

    getProvince() {
      let _ = this;
      axios.get(_.config.province).then((response) => {
        let _ = this;
        _.options_province = response.data.data;
        console.log(_.options_province);
      });
    },

    getCity() {
      let _ = this;
      axios.get(_.config.city + '/' + _.provId).then((response) => {
        let _ = this;
        _.options_city = response.data.data;
        console.log(_.options_city);
      });
    },

    getNames() {
      let _ = this;
      axios.get(_.config.names + '/' + _.model.companyTypeID).then((response) => {
        let _ = this;
        _.option_names = response.data.data;
      });
    },
    getOwnerType() {
      let _ = this;
      axios
        .get(_.config.owner)
        .then((response) => {
          let _ = this;
          _.option_type_owner = response.data.data;
        });
    },
    sanitizeNPWP() {
        let _ = this
        let npwp;
        npwp = _.model.npwp.replaceAll('.','')
        npwp = npwp.replaceAll('-','')

        return npwp
    },
    save() {
      let _ = this;
      let data;
      if (_.table_active == 3) {
        
        data = {
          companyName : this.model.companyName,
          address : this.model.address,
          npwp : this.sanitizeNPWP(),
          companySite : this.model.companySite != "" ? this.model.companySite : null,
          ownershipTypeID : this.model.ownershipTypeID,
          province : this.model.province,
          city : this.model.city,
          companyTypeID : this.model.companyTypeID,
          companyTypeNameID : this.model.companyTypeNameID
        }        
      }else if(_.table_active == 2){
        data = _.model
        delete data.id
      }else{
        data = _.model
      }
      // if (data.companySite == null) {
      //   delete data.companySite
      // }

      axios
        .put(_.config.api + "/" + _.$route.params.id, data)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
          console.log(vm.messages);
        });
    },
  },

  watch: {
    provId: function (newVal, oldVal) {
      this.getCity();
    },
    "model.companyTypeID": function (newVal, oldVal) {
      // console.log(newVal, "ini")
      this.getNames();
    },
  },
};
</script>
