export default [
    {
        path: '/users',
        name: 'users',
        meta: {
            title: 'List User'
        },
        component: () => import('@/views/digirans/user/List.vue'),
    },
    {
        path: '/users/add',
        name: 'users-add',
        meta: {
            title: 'Tambah User',
            action: 'store',
        },
        component: () => import('@/views/digirans/user/Form.vue'),
    },
    // {
    //     path: '/users/:id/show',
    //     name: 'users-show',
    //     component: () => import('@/views/halal-food/users/UsersView.vue'),
    // },
    {
        path: '/users/:id',
        name: 'users-edit',
        meta: {
            title: 'Ubah Data User',
            action: 'edit',
        },
        component: () => import('@/views/digirans/user/Form.vue'),
    },

    {
        path: '/roles',
        name: 'roles',
        meta: {
            title: 'List Role'
        },
        component: () => import('@/views/digirans/role/List.vue'),
    },
    {
        path: '/roles/add',
        name: 'roles-add',
        meta: {
            title: 'Tambah Role',
            action: 'store',
        },
        component: () => import('@/views/digirans/role/Form.vue'),
    },
    {
        path: '/roles/:id',
        name: 'roles-edit',
        meta: {
            title: 'Ubah Data Role',
            action: 'edit',
        },
        component: () => import('@/views/digirans/role/Form.vue'),
    },

    {
        path: '/company-types',
        name: 'company-types',
        meta: {
            title: 'List Jenis Usaha'
        },
        component: () => import('@/views/digirans/type-company/List.vue'),
    },
    {
        path: '/company-types/add',
        name: 'company-types-add',
        meta: {
            title: 'Tambah Jenis Usaha',
            action: 'store',
        },
        component: () => import('@/views/digirans/type-company/Form.vue'),
    },
    {
        path: '/company-types/:id',
        name: 'company-types-edit',
        meta: {
            title: 'Ubah Data Jenis Usaha',
            action: 'edit',
        },
        component: () => import('@/views/digirans/type-company/Form.vue'),
    },

    {
        path: '/company-names',
        name: 'company-names',
        meta: {
            title: 'List Nama Usaha'
        },
        component: () => import('@/views/digirans/name-company/List.vue'),
    },
    {
        path: '/company-names/add',
        name: 'company-names-add',
        meta: {
            title: 'Tambah Nama Usaha',
            action: 'store',
        },
        component: () => import('@/views/digirans/name-company/Form.vue'),
    },
    {
        path: '/company-names/:id',
        name: 'company-names-edit',
        meta: {
            title: 'Ubah Data Nama Usaha',
            action: 'edit',
        },
        component: () => import('@/views/digirans/name-company/Form.vue'),
    },

    {
        path: '/faq-managements',
        name: 'faq-managements',
        meta: {
            title: 'List FAQ'
        },
        component: () => import('@/views/digirans/faq/List.vue'),
    },
    {
        path: '/faq-managements/add',
        name: 'faq-managements-add',
        meta: {
            title: 'Tambah FAQ',
            action: 'store',
        },
        component: () => import('@/views/digirans/faq/Form.vue'),
    },
    {
        path: '/faq-managements/:id',
        name: 'faq-managements-edit',
        meta: {
            title: 'Ubah Data FAQ',
            action: 'edit',
        },
        component: () => import('@/views/digirans/faq/Form.vue'),
    },

    {
        path: '/influencers',
        name: 'influencers',
        meta: {
            title: 'List Influencer'
        },
        component: () => import('@/views/digirans/influencer/List.vue'),
    },

    {
        path: '/influencers/:id',
        name: 'influencers-edit',
        meta: {
            title: 'Ubah Data Influencer',
            action: 'edit',
        },
        component: () => import('@/views/digirans/influencer/Form.vue'),
    },

    {
        path: '/influencers/:id/show',
        name: 'influencers-show',
        meta: {
            title: 'Influencer Detail'
        },
        component: () => import('@/views/digirans/influencer/Show.vue'),
    },

    {
        path: '/influencers/:id/reviw',
        name: 'influencers-review',
        meta: {
            title: 'Review'
        },
        component: () => import('@/views/digirans/influencer/Review.vue'),
    },


    {
        path: '/advertisers',
        name: 'advertisers',
        meta: {
            title: 'List Advertiser'
        },
        component: () => import('@/views/digirans/advertiser/List.vue'),
    },

    {
        path: '/advertisers/:id',
        name: 'advertisers-edit',
        meta: {
            title: 'Update Advertiser',
            action: 'edit',
        },
        component: () => import('@/views/digirans/advertiser/Form.vue'),
    },

    {
        path: '/advertisers-verification',
        name: 'advertisers-verification',
        meta: {
            title: 'Verification Request'
        },
        component: () => import('@/views/digirans/advertiser/VerificationList.vue'),
    },

    {
        path: '/advertisers/:id/show',
        name: 'advertisers-show',
        meta: {
            title: 'Advertiser Detail'
        },
        component: () => import('@/views/digirans/advertiser/Show.vue'),
    },

    {
        path: '/campaigns',
        name: 'campaigns',
        meta: {
            title: 'List Campaign'
        },
        component: () => import('@/views/digirans/campaign/List.vue'),
    },

    {
        path: '/campaigns/:id',
        name: 'campaigns-edit',
        meta: {
            title: 'Informasi Campaign',
            action: 'edit',
        },
        component: () => import('@/views/digirans/campaign/Form.vue'),
    },

    {
        path: '/campaigns/:id/show',
        name: 'campaigns-show',
        meta: {
            title: 'Campaign Detail'
        },
        component: () => import('@/views/digirans/campaign/Show.vue'),
    },

    {
        path: '/category-pricings',
        name: 'category-pricings',
        meta: {
            title: 'List Kategori'
        },
        component: () => import('@/views/digirans/category-pricing/List.vue'),
    },

    {
        path: '/category-pricings/add',
        name: 'category-pricings-add',
        meta: {
            title: 'Tambah Kategori',
            action: 'store',
        },
        component: () => import('@/views/digirans/category-pricing/Form.vue'),
    },

    {
        path: '/category-pricings/:id',
        name: 'category-pricings-edit',
        meta: {
            title: 'Ubah Data Kategori',
            action: 'edit',
        },
        component: () => import('@/views/digirans/category-pricing/Form.vue'),
    },

    {
        path: '/pricing-managements',
        name: 'pricing-managements',
        meta: {
            title: 'List Pricing'
        },
        component: () => import('@/views/digirans/pricing/List.vue'),
    },

    {
        path: '/pricing-managements/add',
        name: 'pricing-managements-add',
        meta: {
            title: 'Tambah Pricing',
            action: 'store',
        },
        component: () => import('@/views/digirans/pricing/Form.vue'),
    },

    {
        path: '/pricing-managements/:id',
        name: 'pricing-managements-edit',
        meta: {
            title: 'Ubah Data Pricing',
            action: 'edit',
        },
        component: () => import('@/views/digirans/pricing/Form.vue'),
    },

    {
        path: '/transaction-managements',
        name: 'transaction-managements',
        meta: {
            title: 'Riwayat Transaksi'
        },
        component: () => import('@/views/digirans/transaction/List.vue'),
    },

    {
        path: '/withdraw-requests',
        name: 'withdraw-requests',
        meta: {
            title: 'Request Withdraw'
        },
        component: () => import('@/views/digirans/withdraw/List.vue'),
    },

    {
        path: '/top-up-history',
        name: 'top-up-history',
        meta: {
            title: 'Riwayat Top Up'
        },
        component: () => import('@/views/digirans/top-up-history/List.vue'),
    },

    {
        path: '/termin-payments',
        name: 'termin-payments',
        meta: {
            title: 'Termin Payment'
        },
        component: () => import('@/views/digirans/termin/List.vue'),
    },

    {
        path: '/termin-payments/:id',
        name: 'termin-payments-edit',
        meta: {
            title: 'Update Termin Payment',
            action: 'edit',
        },
        component: () => import('@/views/digirans/termin/Form.vue'),
    },

    {
        path: '/termin-payments/:id/show',
        name: 'termin-payments-show',
        meta: {
            title: 'Termin Payment Detail'
        },
        component: () => import('@/views/digirans/termin/Show.vue'),
    },

]