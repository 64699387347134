<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge=totalUnread
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifikasi
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{totalUnread}} Baru
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <!-- v-once -->
    <vue-perfect-scrollbar
      
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        
        v-for="notification in allMessage"
        :key="notification.id"
      >
        <b-media @click="readData(notification)">
          <template #aside>
            <!-- <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="notification.type"
            /> -->
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
              
            </span>
            <span class="float-right" v-if="notification.unread == true">
               <b-badge variant="light-primary">
                <feather-icon
                  icon=""
                  class="mr-25"
                />
                <span>Baru</span>
              </b-badge>
            </span>
          </p>
          <small class="notification-text">{{ notification.body }}</small>
          <!-- <small class="notification-text">{{ notification.type_admin }}</small> -->
          <small class="float-right mt-1">{{notification.createdAt_cast}}</small>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li v-show="limit < totalMessage">
      <p class="float-right mt-1 mr-2" @click="limit += 10" style="cursor:pointer">See More</p>
    </li>
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="readAll"
    >Tandai semua sudah di baca</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      allMessage:[],
      totalUnread: 0,
      perfectScrollbarSettings : {},
      limit:10
    }
  },
  beforeMount() {
    this.getNotifications(10)
     this.perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }
  },
  watch: {
    
    limit: function (newVal, oldVal) {
      let _ = this
      console.log(newVal);
      this.getNotifications(newVal)
    }
  },


  methods: {
    readData(data)
    {
        // console.log(data.campaign.id);
        // return;
         axios
        .put("/digirans/notifications/" + data.id + "/read" )
        .then((res) => {
          if (data.type_admin == 'Withdraw') {
            let _ = this;
            window.location = "/withdraw-requests"
          }else if(data.type_admin == 'Advertiser'){
            let _ = this;
            localStorage.setItem('advset', true)
            window.location = "/advertisers"
          }else if(data.type_admin == 'Campaign'){
            let _ = this;
            window.location = "/campaigns/" +  data.campaign.id + "/show"
          }else if(data.type_admin == 'Edit_termin'){
            let _ = this;
            window.location = "/termin-payments/" + data.terminID
          }else if(data.type_admin == 'Termin'){
            localStorage.setItem('terminset', true)
            let _ = this;
            window.location = "/termin-payments"
          }else if(data.type_admin == 'Detail_Termin'){
            let _ = this;
            window.location = "/termin-payments/" + data.terminID + "/show"
          }
        })
        .catch((e) => {
          console.log('Failure');
        });
    },
    readAll()
    {
        axios
        .put("/digirans/notifications/read-all" )
        .then((res) => {
          this.getNotifications(10)
        })
        .catch((e) => {
          console.log('Failure');
        });
    },
    getNotifications(limit)
    {
      let _ = this;
      let limits = limit  
      axios.get("digirans/notifications?limit=" + limits).then((response) => {
        let _ = this;
        _.allMessage = response.data.data.data;
        _.totalUnread = response.data.data.totalUnread;
        _.totalMessage = response.data.data.totalData;
      });
    }
  },
}
</script>

<style>

</style>
