<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col md="12">
            <b-form-group label="Jenis Usaha" label-for="mc-type-company">
              <select class="form-control" v-model="companyType">
                <option
                  v-for="(i, index) in options"
                  :key="index"
                  :value="i.name"
                  @change="changeTypes(i.id)"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Nama Usaha" label-for="mc-type-company">
              <select class="form-control" v-model="companyTypeName">
                <option
                  v-for="(i, index) in option_names"
                  :key="index"
                  :value="i.name"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Keyword" label-for="mc-keyword">
              <b-form-input
                id="mc-keyword"
                placeholder="Cari advertiser disini"
                v-model="keyword"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="reset()"
            >
              Reset
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="float-right mr-1"
              @click="filter()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-tabs pills class="card">
        <b-tab title="Semua" @click="changeUrl(1)">
          <table-digirans
            v-if="table_active == 1"
            apiUrl="advertiser"
            apiBase="digirans/advertiser-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="false"
            verifiersadv="true"
            verifiersadvrequest="false"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Advertiser"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>

        <b-tab title="Verified" @click="changeUrl(2)">
          <table-digirans
            v-if="table_active == 2"
            apiUrl="advertiser"
            apiBase="digirans/advertiser-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="false"
            verifiersadv="false"
            verifiersadvrequest="false"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Advertiser"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>

        <b-tab title="Not Verified" @click="changeUrl(3)">
          <table-digirans
            v-if="table_active == 3"
            apiUrl="advertiser"
            apiBase="digirans/advertiser-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="false"
            verifiersadv="true"
            verifiersadvrequest="false"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Advertiser"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>

        <b-tab
          title="Pengajuan Verified"
          @click="changeUrl(4)"
          :active="act == 1"
        >
          <table-digirans
            v-if="table_active == 4"
            apiUrl="advertiser"
            apiBase="digirans/advertiser-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="false"
            verifiersadv="true"
            confirmApproves="false"
            verifiersadvrequest="false"
            showandhide="false"
            buttonTitle="Advertiser"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}

.nav-pills .nav-link {
  border-radius: 0.358rem;
  margin: 15px 15px 0px 25px;
}
</style>

<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BFormSpinbutton,
  BCard,
} from "bootstrap-vue";
import TableDigirans from "@/views/table/bs-table/TableDigirans";
import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";

import axios from "@axios";

export default {
  name: "InfluencerList",
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    TableDigirans,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormSpinbutton,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        api: "digirans/advertiser-managements?verified=all",
        type: "misc/types/company-types",
        names: "misc/types/company-type-names",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "companyName", label: "Nama Bisnis", sortable: true },
        { key: "fullName", label: "Nama Pengguna", sortable: true },
        { key: "companyType", label: "Jenis Usaha", sortable: true },
        { key: "companyTypeName", label: "Nama Usaha", sortable: true },
        { key: "creditBalance_cast", label: "Kredit Iklan", sortable: true },
        { key: "status_active", label: "Status", sortable: true },
        { key: "Aksi", thClass: "width160px" },
      ],
      items: [],
      addctx: null,
      addcall: null,
      keyword: null,
      companyType: "",
      companyTypeName: null,
      table_active: 1,
      options: [],
      option_names: [],
      act: null,
      genderOptions: [
        {
          value: "semua",
          text: "Semua",
        },
        {
          value: "Laki-Laki",
          text: "Laki-Laki",
        },
        {
          value: "Perempuan",
          text: "Permpuan",
        },
      ],
    };
  },
  mounted() {
    let _ = this;
    _.getType();
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }

    setTimeout(() => {
      if (localStorage.getItem("advset") == "true") {
        this.changeUrl(4);
        this.act = 1;
        localStorage.removeItem("advset");
      }
    }, 3);
  },
  methods: {
    changeUrl(val) {
      this.table_active = val;
      if (val == 1) {
        this.currentPage = 1;
        this.config.api = "digirans/advertiser-managements?verified=all";
        this.getItems(this.addctx, this.addcall);
      } else if (val == 2) {
        this.currentPage = 1;
        this.config.api = "digirans/advertiser-managements?verified=true";
        this.getItems(this.addctx, this.addcall);
      } else if (val == 3) {
        this.currentPage = 1;
        this.config.api = "digirans/advertiser-managements?verified=false";
        this.getItems(this.addctx, this.addcall);
      } else {
        this.currentPage = 1;
        this.config.api =
          "digirans/advertiser-managements?verified=verification";
        this.getItems(this.addctx, this.addcall);
      }
    },
    getItems(ctx, callback) {
      console.log(callback);
      const _ = this;
      this.addctx = ctx;
      this.addcall = callback;
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let filter_type = _.companyType ? "&companyType=" + _.companyType : "";
      let filter_name = _.companyTypeName
        ? "&companyTypeName=" + _.companyTypeName
        : "";
      let filter_keyword = _.keyword ? "&companyName=" + _.keyword : "";
      let orderBy = ctx.sortDesc == true ? "ASC" : "DESC";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      axios
        .get(
          _.config.api +
            "&size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            filter_type +
            filter_name +
            filter_keyword +
            "&sortBy=" +
            sortBy +
            "&orderBy=" +
            orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.perPage;
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    getType() {
      let _ = this;
      axios.get(_.config.type).then((response) => {
        let _ = this;
        _.options = response.data.data;
        console.log(_.options, "ini");
      });
    },
    getNames(id) {
      let _ = this;
      axios.get(_.config.names + "/" + id).then((response) => {
        let _ = this;
        _.option_names = response.data.data;
      });
    },
    // renderTable(perPage, currentPage) {
    //   let _ = this;
    //   axios
    //     .get(_.config.api + "&size=" + perPage + "&page=" + currentPage)
    //     .then((resp) => {
    //       const consume = resp.data.data;
    //       _.totalRows = consume.totalData;
    //       _.currentPage = consume.currentPage;
    //       _.perPage = 10;
    //       _.items = consume.data;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    filter() {
      console.log("Filter Clicked");
      EventBus.$emit("changeValue");
    },
    reset() {
      (this.addctx.filter = null),
        (this.companyType = null),
        (this.keyword = null),
        (this.companyTypeName = null);

      this.getItems(this.addctx, this.addcall);
      this.getNames(1);
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },

  watch: {
    companyType: function(newVal, oldVal) {
      console.log(newVal);
      let tempTypes = this.options;
      tempTypes = tempTypes.filter((item) => {
        return item.name == newVal;
      });
      let ids = tempTypes.length == 1 ? tempTypes[0].id : 0;
      this.getNames(ids);
    },
  },
};
</script>
