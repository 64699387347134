<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img
          src="@/assets/images/logo/new_logo_2.png"
          alt="logo"
          width="200px;"
        />
        <!-- <h2 class="brand-text text-primary ml-1">
          Digirans
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Kode OTP
          </b-card-title>
          <b-card-text class="mb-2">
            Masukan kode OTP yang telah dikirim lewat Email.
          </b-card-text>
          <!-- <b-alert variant="danger" :show="this.response.message != null">
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">{{
                    this.response.message
                  }}</span></small
                >
              </p>
            </div>
          </b-alert> -->
          <!-- form -->
            <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
            />
              <div class="mt-2"> 
                <vac :end-time="new Date().getTime() + 60000">
                    <template
                    v-slot:process="{ timeObj }">
                    <h4 class="text-center">{{ `${timeObj.m}:${timeObj.s}` }}</h4>
                        <b-button
                            :disabled="active_button == false"
                            type="submit"
                            variant="primary"
                            block
                            width="100%"
                            @click="verification"
                        >
                            Verifikasi
                        </b-button>
                    </template>
                    <template
                    v-slot:finish>
                        <p class="text-center">Tidak Menerima Kode ?</p>
                        <b-button
                            type="submit"
                            variant="primary"
                            block
                            width="100%"
                            @click="resendOtp"
                        >
                            Resend OTP
                        </b-button>
                    </template>
                  
                </vac>
              </div>
          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Kembali Ke Login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>


<style>
.d-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    &.error {
      border: 1px solid red !important;
    }
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import axios from "@axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  data() {
    return {
      config:{
        api:'account/reset-password',
        resend:'account/resend-otp'
      },
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      active_button:false,
      response: {
        message: "Sample Error Message",
      },
      model:{
        otp:null
      },
      header_token:localStorage.getItem('reset_token')

    }
  },
  created() {
    console.log(this.header_token);
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    verification()
    {
      let _ = this
      axios.post(_.config.api, {
          otp:_.model.otp
        }, {
          headers: {
            'Authorization': 'Bearer '+ _.header_token
          }
        })
        .then(res => {
            _.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          if (res.data) {
              localStorage.setItem('reset_token', res.data.data.resetPassToken)
          }

          setTimeout(() => {
            window.location = '/reset-password'
          }, 5000);
        }).catch(e => {
            console.log(e.response.data.message);
            _.$toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          })
        })
    },
    resendOtp()
    {
      let _ = this
      axios.post(_.config.resend, {
          
        },{
          headers: {
            'Authorization': 'Bearer '+ _.header_token
          }
        })
        .then(res => {
            localStorage.setItem('reset_token', res.data.data.resetPassToken)
            _.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          location.reload()
          
        }).catch(e => {
            console.log(e.response.data.message);
            _.$toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
    },
    handleOnComplete(value) {
      console.log('OTP completed: ', value);
    },
    handleOnChange(value) {
      console.log('OTP changed: ', value);
      this.model.otp = value
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    validationForm() {
      
    },
  },

  watch:{
        'model.otp': function(newValue) {
          if (newValue.length == 6) {
            this.active_button = true
          } else {
            this.active_button = false
          }  
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
