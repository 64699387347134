import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import idn from "@/libs/idn.json";

// Global Components
import './global-components'

import vueAwesomeCountdown from 'vue-awesome-countdown'
Vue.use(vueAwesomeCountdown, 'vac')

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'


// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)


//moment 

// import VueMoment from 'vue-moment';
// Vue.use(VueMoment);

// Composition API
Vue.use(VueCompositionAPI)

import VueCurrencyFilter from 'vue-currency-filter'
Vue.use(VueCurrencyFilter,[
  {
    symbol : 'Rp.',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  },
  {
    name: 'currency_2',
    symbol : '',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: '.',
    symbolPosition: 'front',
    symbolSpacing: true,
    avoidEmptyDecimals: undefined,
  }
])
  
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
// import { ValidationObserver, localize, extend } from 'vee-validate';
import { localize, ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { email, } from "vee-validate/dist/rules";
import { min } from "vee-validate/dist/rules"
import { confirmed } from "vee-validate/dist/rules"
import { numeric } from "vee-validate/dist/rules"
import { regex } from "vee-validate/dist/rules"

localize({
  idn,
});
localize("idn");
extend("required", required);
extend("email", email);
extend("min", min);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("regex", regex);
extend("numeric", numeric);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
