<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col md="12">
            <b-form-group label="Kategori" label-for="mc-kategori">
              <select class="form-control" v-model="filtering.category">
                <option
                  v-for="(i, index) in categories"
                  :key="index"
                  :value="i.name"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Platform" label-for="mc-platform">
              <select class="form-control" v-model="filtering.platformType">
                <option
                  v-for="(i, index) in platforms"
                  :key="index"
                  :value="i.name"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Layanan" label-for="mc-layana">
              <select class="form-control" v-model="filtering.service">
                <option
                  v-for="(i, index) in services"
                  :key="index"
                  :value="i.name"
                >
                  {{ i.name }}
                </option>
              </select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="reset()"
            >
              Reset
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="float-right mr-1"
              @click="filter()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <table-digirans
        apiUrl="pricing-management"
        apiBase="digirans/pricing-managements"
        creates="true"
        updates="true"
        deletes="true"
        shows="false"
        search="true"
        verifiers="false"
        confirmApproves="false"
        showandhide="false"
        buttonTitle="Pricing"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-digirans>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BFormSpinbutton,
  BCard,
} from "bootstrap-vue";
import TableDigirans from "@/views/table/bs-table/TableDigirans";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";

export default {
  name: "UsersList",
  components: {
    BRow,
    BCol,
    TableDigirans,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormSpinbutton,
    BCard,
  },
  data() {
    return {
      config: {
        api: "digirans/pricing-managements",
        category: "misc/types/categories",
        platform: "misc/types/platform-types",
        service: "misc/types/services",
      },
      addctx:null,
      addcall:null,
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "category", label: "Kategori", sortable: true },
        { key: "category.range_cast", label: "Range", sortable: true },
        { key: "platformType", label: "Platform", sortable: true },
        { key: "service", label: "Layanan", sortable: true },
        { key: "price_cast", label: "Harga", sortable: true },
        { key: "Aksi", thClass: "width160px" },
      ],
      filtering: {
        category: "",
        platformType: "",
        service: "",
      },
      items: [],
      categories: [],
      platforms: [],
      services: [],
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
    _.getCategory();
    _.getPlatform();
  },
  methods: {
    getItems(ctx, callback) {
      const _ = this;
      this.addctx = ctx;
      this.addcall = callback;
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? "DESC" : "ASC";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      console.log(filter);
      axios
        .get(
          _.config.api +
            "?size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&filtering="+ JSON.stringify(this.filtering)+
            "&sortBy=" +
            sortBy +
            "&orderBy=" +
            orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          // _.perPage = consume.perPage
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      console.log(perPage, currentPage, "log");
      let a = currentPage;

      let _ = this;
      axios
        .get(_.config.api + "?size=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          _.perPage = 10;
          // _.perPage = consume.perPage
          _.items = consume.data;

          console.log(_.totalRows, _.currentPage, _.perPage, "ininini");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategory() {
      let _ = this;
      axios.get(_.config.category).then((response) => {
        let _ = this;
        _.categories = response.data.data;
      });
    },
    getService(id) {
      let _ = this;
      axios.get(_.config.service + "/" + id).then((response) => {
        let _ = this;
        _.services = response.data.data;
      });
    },
    getPlatform() {
      let _ = this;
      axios.get(_.config.platform).then((response) => {
        let _ = this;
        _.platforms = response.data.data;
      });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    reset()
    {
      this.filtering = {
        service: "",
        platformType: "",
        category: "",
      };
      this.services=[],
      this.getItems(this.addctx, this.addcall);
      
    },
    filter() {
      this.getItems(this.addctx, this.addcall);
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
  watch: {
    "filtering.platformType": function (newVal, oldVal) {
      let _ = this
      console.log(_.platforms);

      let platformID = this.platforms;
      platformID = platformID.filter((item) => {
        return item.name == newVal;
      });
      // console.log(platformID[0].id);
      this.getService(platformID[0].id);
    },
  },
};
</script>