<template>
  <b-card>
    <!-- form -->
    <b-alert
      v-height-fade
      dismissible
      v-model="showDismissibleAlert"
      fade
      class="mb-2"
      variant="danger"
      v-id="showDismissibleAlert == true"
    >
      <div class="alert-body">
        <span>{{ messages }}</span>
      </div>
    </b-alert>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)" class="mt-2">
        <b-row>
          <b-col md="12">
            <ValidationProvider
              name="Nama Advertiser"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Nama Advertiser"
                label-for="mc-nama-advertiser"
              >
                <b-form-input
                  id="mc-nama-advertiser"
                  placeholder="Nama Advertiser"
                  v-model="model.advertiserName"
                  disabled
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Nama Campaign"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Nama Campaign" label-for="mc-nama-campaign">
                <select class="form-control" v-model="model.campaignID" disabled>
                  <option
                    v-for="(i, index) in options_campaign"
                    :key="index"
                    :value="i.id"
                  >
                    {{ i.name }}
                  </option>
                </select>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Total Pembayaran"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Total Pembayaran"
                label-for="mc-total-pembayaran"
              >
                <b-form-input
                  id="mc-total-pembayaran"
                  placeholder="Total Pembayaran"
                  v-model="model.paymentAmount"
                  disabled
                />
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Total Termin"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Total Termin"
                label-for="mc-total-termin"
              >
                <b-form-input
                  id="mc-total-termin"
                  placeholder="Total Termin"
                  v-model="model.amount"
                />
                <i>Berapa kali pembayaran</i>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>


          <b-col md="6">
            <ValidationProvider
              name="Total Pembayaran Termin"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Total Pembayaran Termin"
                label-for="mc-total-pembayaran-termin"
              >
                <!-- <b-form-input
                  id="mc-total-pembayaran-termin"
                  placeholder="Total Pembayaran Termin"
                  v-model="model.payment"
                /> -->
                <vue-numeric class="form-control" v-bind:minus="false" v-bind:precision="2" currency="Rp." separator="." v-model="model.payment"></vue-numeric>
                <i>Per sekali pembayaran</i>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <!-- :disabled-date="notAfterToday" -->
          <b-col md="6">
            <ValidationProvider
              name="Tanggal Cetak Invoice Tagihan"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Tanggal Cetak Invoice Tagihan"
                label-for="mc-date-birth"
              >
                <date-picker
                  :firstDayOfWeek="1"
                  v-model="model.createdAt"
                  name="tangggal_cetak_invoice_tagihan"
                  :lang="lang"
                  :format="formats"
                ></date-picker>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Tanggal Batas Pembayaran"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Tanggal Batas Pembayaran"
                label-for="mc-date-birth"
              >
                <date-picker
                  disabled
                  :firstDayOfWeek="1"
                  v-model="model.endDate"
                  name="tanggal_batas_pembayaran"
                  :lang="lang"
                  :format="formats"
                ></date-picker>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <b-form-group label="Upload Dokumen" label-for="mc-upload-dokumen">
              <input type="file" class="form-control" @change="uploadImage" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-card-footer>
          <!-- submit and reset -->
           <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'termin-payments' }"
          >
            Batalkan
          </b-button>
        
          <b-button
            v-show="model.status == 'waiting-for-confirmation'"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="danger"
            @click="decline()"
          >
            Tolak Pengajuan
          </b-button>

          <b-button
            v-show="model.status == 'waiting-for-confirmation' || model.status == 'reviewed'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 float-right"
          >
            Kirim
          </b-button>
         
        </b-card-footer>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
.mx-input:disabled, .mx-input.disabled {
    color: #333;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueNumeric from 'vue-numeric'
export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    VueNumeric
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      options_campaign: [],
      name: "InfluencerForms",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "digirans/termin-payments",
        redirect: "termin-payments",
        campaign: "misc/types/campaigns/",
        put_data : "digirans/termin-payments/"
      },
      createdAt:'',
      endDate:'',
      model: {
        advertiserName:''
      },
      messages: "",
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
      provId: null,
    };
  },
  mounted() {
    let _ = this;
      _.getData();
    console.log(_.$route.meta.action);
  },
  methods: {
    uploadImage(e) {
      let vm = this;
      this.model.attachment = e.target.files[0];
      console.log(this.model.attachment);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
            var str = _.model.endDate;
            if (_.model.endDate != null) {
              var res = str.split("-");
              // console.log(res[res.length - 1], res[res.length - 2], res[res.length - 3]);
              _.model.endDate = new Date(
                res[res.length - 3],
                res[res.length - 2] -1,
                res[res.length - 1]
              );  
            }

            var str1 = _.model.createdAt;
            if (_.model.createdAt != null) {
              var res1 = str1.split("-");
              // console.log(res1[res1.length - 1], res1[res1.length - 2], res1[res1.length - 3]);
              // _.model.createdAt = new Date(
              //   res1[res1.length - 3],
              //   res1[res1.length - 2] -1,
              //   res1[res1.length - 1]
              // );

              // let batasAkhir = _.model.createdAt.setDate(_.model.createdAt.getDate() + 2)
              // var strToDate = new Date(batasAkhir);
              // _.model.endDate = strToDate
            }


            _.model.advertiserName = _.model.advertiser.fullName
            var AdvID = _.model.advertiserID
            _.config.campaign = "misc/types/campaigns/" + AdvID
            _.getCampaign(_.config.campaign)
          });
      }
    },

    getCampaign(url) {
      let _ = this;
      axios.get(url).then((response) => {
        let _ = this;
        _.options_campaign = response.data.data;
      });
    },

    decline()
    {
      let _ = this;
      _.model.id = _.$route.params.id;
      
      let data = {
        status:"rejected"
      };

      console.log(data);
      axios
        .put(_.config.put_data + _.$route.params.id, data)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
      
    },

    save() {
      let _ = this;
      _.model.id = _.$route.params.id;
      
      let data = {
        advertiserID: _.model.advertiserID,
        campaignID: _.model.campaignID,
        paymentAmount: _.model.paymentAmount,
        amount: _.model.amount,
        payment: _.model.payment,
        createdAt: _.createdAt,
        endDate: _.endDate,
        attachment: _.model.attachment,
        status:"reviewed"
      };

      console.log(data);

      let formData = new FormData();
      formData.append('advertiserID', data.advertiserID)
      formData.append('campaignID', data.campaignID)
      formData.append('paymentAmount', data.paymentAmount)
      formData.append('amount', data.amount)
      formData.append('payment', data.payment)
      formData.append('createdAt', data.createdAt)
      formData.append('endDate', data.endDate)
      formData.append('attachment', data.attachment)
      formData.append('status', data.status)

      console.log(data);
      axios
        .put(_.config.put_data + _.$route.params.id, formData)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
  },
  watch: {
    'model.createdAt': function (newVal, oldVal) {
      if (newVal != null) {
        // console.log("ini new Val", newVal);
        let _ = this
        let a = newVal.toJSON().slice(0, 10);   
        var res = a.split("-");
        let add = parseInt(res[res.length - 2]) < 10 ? 0 : '' 
        let addDate = parseInt(res[res.length - 1]) < 10 ? 0 : '' 
        _.createdAt = parseInt(res[res.length - 3]) + '-' + add + parseInt(res[res.length - 2]) +'-'+ addDate +(parseInt(res[res.length - 1])+1);
        let val = newVal
        let batasAkhir = val.toISOString().substr(0, 10) 
        var strToDate = new Date(batasAkhir)
        var final = strToDate.setDate(strToDate.getDate() + 3);
        _.model.endDate = strToDate
        // console.log(_.createdAt);
      }
    },
    'model.endDate': function (newVal, oldVal) {
      if (newVal != null) {
        console.log(newVal);
        let _ = this
        let a = newVal.toJSON().slice(0, 10);   
        var res = a.split("-");
        let add = parseInt(res[res.length - 2]) < 10 ? 0 : '' 
        let addDate = parseInt(res[res.length - 1]) < 10 ? 0 : '' 
        _.endDate = parseInt(res[res.length - 3]) + '-' + add + parseInt(res[res.length - 2]) +'-'+ addDate + (parseInt(res[res.length - 1]));
        // console.log(_.endDate);     
      }
    }
  },
};
</script>
