<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Kategori"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Kategori" label-for="mc-kategori">
                    <select class="form-control" v-model="model.categoryID">
                      <option
                        v-for="(i, index) in categories"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Platform"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Platform" label-for="mc-platform">
                    <select class="form-control" v-model="model.platformTypeID">
                      <option
                        v-for="(i, index) in platforms"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Layanan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Layanan" label-for="mc-layana">
                    <select class="form-control" v-model="model.serviceID">
                      <option
                        v-for="(i, index) in services"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Harga"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Harga" label-for="mc-price">
                    <!-- <b-form-input
                      id="mc-price"
                      placeholder="Harga"
                      v-model="model.price"
                    /> -->
                    <vue-numeric class="form-control" v-bind:minus="false" v-bind:precision="2" currency="Rp." separator="." v-model="model.price"></vue-numeric>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                  
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'pricing-managements' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import VueNumeric from 'vue-numeric'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    VueNumeric
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "UsersForm",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "digirans/pricing-managements",
        category: "misc/types/categories",
        platform: "misc/types/platform-types",
        service: "misc/types/services",
        redirect: "pricing-managements",
      },
      model: {},
      messages: "",
      categories: [],
      platforms: [],
      services: [],
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getCategory();
    _.getPlatform();
    console.log(_.$route.meta.action);
  },
  methods: {
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
          });
      }
    },
    getCategory() {
      let _ = this;
      axios.get(_.config.category).then((response) => {
        let _ = this;
        _.categories = response.data.data;
      });
    },
    getPlatform() {
      let _ = this;
      axios.get(_.config.platform).then((response) => {
        let _ = this;
        _.platforms = response.data.data;
      });
    },
    getService(id) {
      let _ = this;
      axios.get(_.config.service + "/" + id).then((response) => {
        let _ = this;
        _.services = response.data.data;
      });
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                variant: "success",
                message: res.data.message,
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === "object") {
              vm.messages = e.response.data.message.errors[0].msg;
            } else {
              vm.messages = e.response.data.message;
            }
          });
      }
    },
  },

  watch: {
    "model.platformTypeID": function (newVal, oldVal) {
      this.getService(newVal);
    },
  },
};
</script>
