<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand" to="/">
              <span class="brand-logo ml-1">
                <b-img :src="appLogoImage" alt="logo" />
              </span>
              <h2 class="brand-text">
                <!-- {{ appName }} -->
              </h2>
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        (evt) => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <vertical-nav-menu-items
        :items="data"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<style>
.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 200px !important;
}
</style>
<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },

  created() {
    let a = JSON.parse(localStorage.getItem("userData"));
    let tamp = a.rolePermissions;
    for (let index = 0; index < tamp.length; index++) {
      this.data.push(tamp[index].permission);
    }
    this.realese();
  },

  methods: {
    realese() {
      const mapp = this.data.reverse().map((item) => {
        const container = {};
        container.id = item.id;
        container.title = item.title;
        container.route = item.route;
        container.icon = item.icon;
        container.children = this.checking(item.children);
        return container;
      });

      console.log(mapp, "ini Methods");

      this.data = mapp;
      let add = [
        {
          title: "Dashboard",
          route: "home",
          icon: "HomeIcon",
        },
      ];

      add = add.reverse();

      for (let index = 0; index < add.length; index++) {
        this.data.push(add[index]);
      }
      this.data = this.data.reverse();
    },
    checking(data) {
      if (data == []) {
        return null;
      } else {
        // return JSON.parse(data);
        console.log(data.length);
        if (data.length == 4) {
          return null;
        } else {
          return JSON.parse(data);
        }
      }
    },
  },
  data() {
    return {
      menu_data: {},
      data: [],
    };
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    let data = [
      {
        title: "User Management",
        icon: "UsersIcon",
        tagVariant: "light-warning",
        children: [
          {
            title: "User List",
            route: "users",
          },
          {
            title: "Role",
            route: "roles",
          },
        ],
      },

      {
        title: "Influencer Management",
        route: "influencers",
        icon: "UsersIcon",
      },
      {
        title: "Advertiser Management",
        icon: "UsersIcon",
        route: "advertisers",
      },

      {
        title: "Campaign Management",
        route: "campaigns",
        icon: "ImageIcon",
      },

      {
        title: "Billing Management",
        icon: "ClockIcon",
        tagVariant: "light-warning",
        children: [
          {
            title: "Riwayat Transaksi",
            route: "transaction-managements",
          },
          {
            title: "Request Withdraw",
            route: "withdraw-requests",
          },
          {
            title: "Termin Payment",
            route: "termin-payments",
          },
        ],
      },

      {
        title: "Content Management",
        icon: "FileTextIcon",
        tagVariant: "light-warning",
        children: [
          {
            title: "FAQ Management",
            route: "faq-managements",
          },
          {
            title: "Jenis Usaha",
            route: "company-types",
          },
          {
            title: "Nama Usaha",
            route: "company-names",
          },
        ],
      },

      {
        title: "Pricing Management",
        icon: "DollarSignIcon",
        tagVariant: "light-warning",
        children: [
          {
            title: "Pricing List",
            route: "pricing-managements",
          },
          {
            title: "Kategori Pricing",
            route: "category-pricings",
          },
        ],
      },
    ];

    // navMenuItems = []
    // for (let index = 0; index < data; index++) {
    //   console.log(data[index]);
    //   navMenuItems.push(data[index])
    // }

    // console.log(navMenuItems);

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
