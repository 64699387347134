<template>
  <b-row>
    <b-col cols="12">
      <table-digirans
        :tableTitle=this.$route.meta.title
        apiUrl="campaign"
        apiBase="digirans/campaign-managements"
        creates="false"
        updates="true"
        deletes="false"
        shows="true"
        search="true"
        verifiers="false"
        confirmApproves="false"
        showandhide="true"
        buttonTitle="Campaign"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-digirans>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}

.nav-pills .nav-link {
  border-radius: 0.358rem;
  margin: 15px 15px 0px 25px;
}
</style>

<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BFormSpinbutton,
  BCard,
} from "bootstrap-vue";
import TableDigirans from "@/views/table/bs-table/TableDigirans";
import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";

import axios from "@axios";

export default {
  name: "InfluencerList",
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    TableDigirans,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormSpinbutton,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        api: "digirans/campaign-managements",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "orderID", label: "ORDER ID" ,sortable: true},
        { key: "name", label: "Campaign Name" ,sortable: true},
        { key: "advertiser.companyName", label: "Advertiser" ,sortable: true},
        { key: "status_active", label: "Status Advertiser"},
        { key: "startDate_cast", label: "Tanggal Mulai" ,sortable: true},
        { key: "endDate_cast", label: "Tanggal Selesai" ,sortable: true},
        { key: "status", label: "Status Campaign" ,sortable: true},
        {key: 'Aksi', thClass: 'width160px'}  
      ],
      items: [],
      addctx: null,
      addcall: null,
     
    };
  },
  mounted() {
    let _ = this;
  },
  methods: {
    getItems(ctx, callback) {
      console.log(ctx);
      const _ = this;
      this.addctx = ctx;
      this.addcall = callback;
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
       let orderBy = ctx.sortDesc == true ? 'ASC' : 'DESC'
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length-1]
      if (sortBy.includes("_cast") == true) {
          sortBy = sortBy.split('_')
          sortBy = sortBy[0]
      }
      axios
        .get(
          _.config.api +
            "?size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&sortBy="+ sortBy + 
            "&orderBy="+ orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.perPage;
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      console.log(perPage, currentPage);
      let _ = this;
      axios
        .get(_.config.api + "&size=" + perPage + "&page=" + currentPage)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          _.perPage = 10;
          _.items = consume.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>