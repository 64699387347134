<template>
  <b-row>
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col md="12">
            <b-form-group label="Jenis Kelamin" label-for="mc-gender">
              <b-form-radio-group
                v-model="gender"
                :options="genderOptions"
                class="mt-1"
                value-field="value"
                text-field="text"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Minimal Usia" label-for="mc-min-age">
              <b-form-input
                id="mc-min-age"
                placeholder="Minimal Usia"
                v-model="min_age"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
              <b-form-group label="Maksimal Usia" label-for="mc-amax-age">
              <b-form-input
                id="mc-max-age"
                placeholder="Maksimal Usia"
                v-model="max_age"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group label="Nama Influencer" label-for="mc-keyword">
              <b-form-input
                id="mc-keyword"
                placeholder="Cari Disini"
                v-model="keyword"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="reset()"
            >
              Reset
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              class="float-right mr-1"
              @click="filter()"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-tabs pills class="card">
        <b-tab title="Semua" @click="changeUrl(1)" active>
          <table-digirans
            v-if="table_active == 1"
            apiUrl="influencer"
            apiBase="digirans/influencer-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="true"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Influencer"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>

        <b-tab title="Verified" @click="changeUrl(2)">
          <table-digirans
            v-if="table_active == 2"
            apiUrl="influencer"
            apiBase="digirans/influencer-managements"
            creates="false"
            updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="true"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Influencer"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>

        <b-tab title="Not Verified" @click="changeUrl(3)">
          <table-digirans
            v-if="table_active == 3"
            apiUrl="influencer"
            apiBase="digirans/influencer-managements"
            creates="false"
             updates="true"
            deletes="false"
            shows="true"
            search="false"
            verifiers="true"
            confirmApproves="false"
            showandhide="false"
            buttonTitle="Influencer"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
          </table-digirans>
        </b-tab>
      </b-tabs>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}

.nav-pills .nav-link {
    border-radius: 0.358rem;
    margin: 15px 15px 0px 25px;
}

.vue-slider-process {
    background-color: #e83e8c;
}
.vue-slider:hover .vue-slider-process {
    background-color: #e83e8c;
}

.vue-slider-dot-handle-focus {
    border-color: #e83e8c;
}

.vue-slider-dot-handle {
    cursor: pointer;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #e83e8c;
}

.vue-slider:hover .vue-slider-dot-handle:hover{
  border-color: #e83e8c;
}

</style>

<script>
import {
  BRow,
  BCol,
  BTab,
  BTabs,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BFormRadioGroup,
  BFormSpinbutton,
  BCard,
} from "bootstrap-vue";
import TableDigirans from "@/views/table/bs-table/TableDigirans";
import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'


import axios from "@axios";

export default {
  name: "InfluencerList",
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    TableDigirans,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRadioGroup,
    BFormSpinbutton,
    BCard,
    VueSlider
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        api: "digirans/influencer-managements?verified=all",
        age: "misc/types/age"
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "fullName", label: "Nama Influencer" ,sortable: true },
        { key: "age", label: "Usia" ,sortable: true },
        { key: "gender", label: "Jenis Kelamin", sortable: true  },
        { key: "status_active", label: "Status",sortable: true },
        { key: "Aksi", thClass: "width160px" },
      ],
      items: [],
      addctx: null,
      addcall: null,
      keyword: null,
      gender: "",
      spin_start: null,
      spin_end: null,
      table_active: 1,
      genderOptions: [
        {
          value: "semua",
          text: "Semua",
        },
        {
          value: "Laki-Laki",
          text: "Laki-Laki",
        },
        {
          value: "Perempuan",
          text: "Perempuan",
        },
      ],
      min_age:null,
      max_age:null
    };
  },
  mounted() {
    // this.getAge()
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
    // _.getAge()
    
  },
  methods: {
    changeUrl(val) {
      console.log(val);
      this.table_active = val;
      if (val == 1) {
        this.currentPage = 1
        this.config.api = "digirans/influencer-managements?verified=all";
        this.getItems(this.addctx, this.addcall);
      } else if (val == 2) {
        this.currentPage = 1
        this.config.api = "digirans/influencer-managements?verified=true";
        this.getItems(this.addctx, this.addcall);
      } else {
        this.currentPage = 1
        this.config.api = "digirans/influencer-managements?verified=false";
        this.getItems(this.addctx, this.addcall);
      }
      
    },
    getItems(ctx, callback) {
      const _ = this;
      
      this.addctx = ctx;
      this.addcall = callback;
      console.log(ctx, "ini ctx");
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let filter_gender = _.gender ? "&gender=" + _.gender : "";
      let filter_keyword = _.keyword ? "&name=" + _.keyword : "";
      let filter_age = null
      if (_.min_age !== null && _.max_age !== null) {
        filter_age = "&age=" + _.min_age + "-" + _.max_age  
      }else{
        filter_age = "&age="
      }

      console.log(filter_age);
      
      let orderBy = ctx.sortDesc == true ? "ASC" : "DESC";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      axios
        .get(
          _.config.api +
            "&size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            filter_gender +
            filter_keyword +
            filter_age +
            "&sortBy=" +
            sortBy +
            "&orderBy=" +
            orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = consume.perPage;
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    getAge() {
      let _ = this;
      axios.get(_.config.age).then((response) => {
        let _ = this;
        console.log(response.data.data.min.age , "ni Response");
      });
    },
    filter() {
      console.log("Filter Clicked");
      EventBus.$emit("changeValue");
    },
    reset() {
      (this.addctx.filter = null),
        (this.keyword = null),
        (this.max_age = null),
        (this.min_age = null),
        (this.gender = null);

      this.getItems(this.addctx, this.addcall);
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
  watch: {
    
  },
};
</script>