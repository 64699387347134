<template>
  <b-card :title="this.$route.meta.title">
    <b-tabs pills justified>
      <b-tab title="Profil" active>
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mt-2 mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <!-- media -->
        <b-media class="mt-2" no-body>
          <b-media-aside>
            <b-link>
              <!-- <b-img
                ref="previewEl"
                rounded="circle"
                :src="model.account.avatar"
                height="80"
                width="80"
              /> -->
              <b-avatar :src="model.account.avatar" class="mb-1" size="90px"></b-avatar>

            </b-link>
          </b-media-aside>
        </b-media>
        <!--/ media -->
        <!-- form -->
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)" class="mt-2">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Depan"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Depan" label-for="mc-firstname">
                    <b-form-input
                      id="mc-firstname"
                      placeholder="Masukkan Nama Depan"
                      v-model="model.firstName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nama Belakang"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Belakang" label-for="mc-lastname">
                    <b-form-input
                      id="mc-lastname"
                      placeholder="Masukkan Nama Belakang"
                      v-model="model.lastName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Tanggal Lahir"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Tanggal Lahir" label-for="mc-date-birth">
                    <date-picker
                      :firstDayOfWeek="1"
                      v-model="model.dateOfBirth"
                      name="date_of_birth"
                      :lang="lang"
                      :format="formats"
                      :disabled-date="notTheseDay"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Usia"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Usia" label-for="mc-usia">
                    <b-form-input
                      id="mc-usia"
                      disabled
                      placeholder="Usia"
                      v-model="model.age"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Alamat Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Alamat Email" label-for="mc-email">
                    <b-form-input
                      id="mc-email"
                      placeholder="Masukkan Alamat Email"
                      v-model="model.email"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nomor Telepon"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nomor Telepon" label-for="mc-no-telepon">
                    <b-form-input
                      id="mc-no-telepon"
                      placeholder="Masukkan Nomor Telepon"
                      v-model="model.phoneNumber"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Provinsi"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Provinsi" label-for="mc-provinsi">
                    <select
                      class="form-control"
                      v-model="model.province"
                      @change="getProvinceId()"
                    >
                      <option
                        v-for="(i, index) in options_province"
                        :key="index"
                        :value="i.name"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Kota"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Kota/Kabupaten" label-for="mc-kota">
                    <select class="form-control" v-model="model.city">
                      <option
                        v-for="(i, index) in options_city"
                        :key="index"
                        :value="i.name"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="NPWP Perusahaan"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Nomor NPWP"
                    label-for="mc-npwp-perusahaan"
                  >
                    <b-form-input
                      id="mc-npwp-perusahaan"
                      placeholder="Masukkan Nomor NPWP"
                      v-model="model.npwp"
                      v-mask="'##.###.###.#-###.###'"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Jenis Kelamin"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Jenis Kelamin" label-for="mc-gender">
                    <b-form-radio-group
                      v-model="model.gender"
                      :options="genderOptions"
                      class="mt-1"
                      value-field="value"
                      text-field="text"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="12" class="mb-4">
                <p>Tipe Konten</p>
                <div
                  style="
                    border: 2px solid #e83e8c;
                    border-radius: 5px;
                    min-height: 120px;
                    padding: 10px;
                  "
                >
                  <div
                    v-for="(i, index) in model.contentTypes"
                    :key="index"
                    style="float: left; margin-top:5px;"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      pill
                      disabled
                      class="mr-1"
                    >
                      {{ i.name }}
                    </b-button>
                  </div>
                </div>
              </b-col>

              <b-col md="12">
                <label for="textarea-auto-height">Alamat</label>
                <b-form-textarea
                  id="textarea-auto-height"
                  placeholder="Alamat"
                  rows="3"
                  v-model="model.address"
                  max-rows="8"
                  class="mb-1 mb-xl-0"
                />
              </b-col>

              <!-- <b-col md="12">
                <label for="textarea-auto-height">Tipe Konten</label>
                 <v-select
                  v-model="model.contentTypes"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="title"
                  class="select-size-lg"
                  :options="books"
                />
               
              </b-col> -->

              <!-- <b-col md="12" class="mt-2">
            <b><p>Social Media</p></b>
          </b-col>

          <b-col md="12">
            <ValidationProvider
              name="Link Instagram"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Link Instagram"
                label-for="mc-link-Instagram"
              >
                <b-form-input
                  id="mc-link-Instagram"
                  placeholder="Link Instagram"
                  v-model="model.instagram"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider
              name="Link Facebook"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Link Facebook" label-for="mc-link-facebook">
                <b-form-input
                  id="mc-link-facebook"
                  placeholder="Link Facebook"
                  v-model="model.facebook"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider
              name="Link Youtube"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Link Youtube" label-for="mc-link-youtube">
                <b-form-input
                  id="mc-link-youtube"
                  placeholder="Link Youtube"
                  v-model="model.youtube"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col> -->
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'influencers' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Info Rekening">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(saveRekening)" class="mt-2">
            <b-row>
              <b-col md="12">
                <ValidationProvider
                  name="Nama Bank"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Nama Bank" label-for="mc-bank-name">
                    <select class="form-control" v-model="model_bank.bank">
                      <option
                        v-for="(i, index) in bank"
                        :key="index"
                        :value="i.name"
                      >
                        {{ i.name }}
                      </option>
                    </select>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nomor Rekening"
                  rules="required|numeric"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Nomor Rekening"
                    label-for="mc-account-number"
                  >
                    <b-form-input
                      id="mc-account-number"
                      placeholder="Masukkan Nomor Rekening"
                      v-model="model_bank.bankAccountNumber"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="6">
                <ValidationProvider
                  name="Nama Rekening"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Nama Rekening"
                    label-for="mc-account-name"
                  >
                    <b-form-input
                      id="mc-account-name"
                      placeholder="Masukkan Nama Rekening"
                      v-model="model_bank.bankAccountName"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'influencers' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-tab>
      <b-tab title="Sosial Media">
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(saveRekening)" class="mt-2">
            <b-row>
              <b-col md="10">
                <ValidationProvider
                  name="Instagram"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-group label="Instagram" label-for="mc-instagram">
                    <b-form-input
                      id="mc-instagram"
                      placeholder="Instagram"
                      v-model="listSocmed.instagram"
                      disabled
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

                        
              <b-col md="2" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1 float-right"
                  @click="instagramReset(1)"
                  :disabled="listSocmed.instagram == null"            
                >
                  Reset
                </b-button>
              </b-col>

              <b-col md="10">
                <ValidationProvider
                  name="Facebook"
                  rules=""
                  v-slot="{ errors }"
                >
                  <b-form-group label="Facebook" label-for="mc-facebook">
                    <b-form-input
                      id="mc-facebook"
                      placeholder="Facebook"
                      v-model="listSocmed.facebook"
                      disabled
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="2" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="instagramReset(2)"
                  variant="primary"
                  class="mr-1 float-right"
                  :disabled="listSocmed.facebook == null"
                >
                  Reset
                </b-button>
              </b-col>

              <b-col md="10">
                <ValidationProvider name="Youtube" rules="" v-slot="{ errors }">
                  <b-form-group label="Youtube" label-for="mc-youtube">
                    <b-form-input
                      id="mc-youtube"
                      placeholder="Youtube"
                      v-model="listSocmed.youtube"
                      disabled
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col md="2" class="mt-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="youtubeReset()"
                  variant="primary"
                  class="mr-1 float-right"
                  :disabled="listSocmed.youtube == null"                  
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {mask} from 'vue-the-mask'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    BAvatar,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    mask
  },
  data() {
    return {
      options_city: [],
      options_province: [],
      name: "InfluencerForms",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "digirans/influencer-managements",
        redirect: "influencers",
        province: "misc/types/provinces",
        city: "misc/types/city",
        bank: "digirans/influencer-managements/bank/",
        update_bank: "digirans/influencer-managements/bank/",
        socmed: "digirans/influencer-managements/socmed/"
      },
      model: {
        youtube: null,
        facebook: null,
        instagram: null,
        account: {
          avatar: "",
        },
      },
      listSocmed:{
        youtube: null,
        facebook: null,
        instagram: null,
      },
      messages: "",
      genderOptions: [
        { text: "Laki-Laki", value: "Laki-Laki" },
        { text: "Perempuan", value: "Perempuan" },
      ],
      dir: "ltr",
      selected: [{ title: "Square" }, { title: "Romboid" }],
      socials: [],

      bank: [
        {
          name: "BANK BRI",
          code: "002",
        },
        {
          name: "BANK EKSPOR INDONESIA",
          code: "003",
        },
        {
          name: "BANK MANDIRI",
          code: "008",
        },
        {
          name: "BANK BNI",
          code: "009",
        },
        {
          name: "BANK BNI SYARIAH",
          code: "427",
        },
        {
          name: "BANK DANAMON",
          code: "011",
        },
        {
          name: "PERMATA BANK",
          code: "013",
        },
        {
          name: "BANK BCA",
          code: "014",
        },
        {
          name: "BANK BII",
          code: "016",
        },
        {
          name: "BANK PANIN",
          code: "019",
        },
        {
          name: "BANK ARTA NIAGA KENCANA",
          code: "020",
        },
        {
          name: "BANK NIAGA",
          code: "022",
        },
        {
          name: "BANK BUANA IND",
          code: "023",
        },
        {
          name: "BANK LIPPO",
          code: "026",
        },
        {
          name: "BANK NISP",
          code: "028",
        },
        {
          name: "AMERICAN EXPRESS BANK LTD",
          code: "030",
        },
        {
          name: "CITIBANK N.A.",
          code: "031",
        },
        {
          name: "JP. MORGAN CHASE BANK, N.A.",
          code: "032",
        },
        {
          name: "BANK OF AMERICA, N.A",
          code: "033",
        },
        {
          name: "ING INDONESIA BANK",
          code: "034",
        },
        {
          name: "BANK MULTICOR TBK.",
          code: "036",
        },
        {
          name: "BANK ARTHA GRAHA",
          code: "037",
        },
        {
          name: "BANK CREDIT AGRICOLE INDOSUEZ",
          code: "039",
        },
        {
          name: "THE BANGKOK BANK COMP. LTD",
          code: "040",
        },
        {
          name: "THE HONGKONG & SHANGHAI B.C.",
          code: "041",
        },
        {
          name: "THE BANK OF TOKYO MITSUBISHI UFJ LTD",
          code: "042",
        },
        {
          name: "BANK SUMITOMO MITSUI INDONESIA",
          code: "045",
        },
        {
          name: "BANK DBS INDONESIA",
          code: "046",
        },
        {
          name: "BANK RESONA PERDANIA",
          code: "047",
        },
        {
          name: "BANK MIZUHO INDONESIA",
          code: "048",
        },
        {
          name: "STANDARD CHARTERED BANK",
          code: "050",
        },
        {
          name: "BANK ABN AMRO",
          code: "052",
        },
        {
          name: "BANK KEPPEL TATLEE BUANA",
          code: "053",
        },
        {
          name: "BANK CAPITAL INDONESIA, TBK.",
          code: "054",
        },
        {
          name: "BANK BNP PARIBAS INDONESIA",
          code: "057",
        },
        {
          name: "BANK UOB INDONESIA",
          code: "058",
        },
        {
          name: "KOREA EXCHANGE BANK DANAMON",
          code: "059",
        },
        {
          name: "RABOBANK INTERNASIONAL INDONESIA",
          code: "060",
        },
        {
          name: "ANZ PANIN BANK",
          code: "061",
        },
        {
          name: "DEUTSCHE BANK AG.",
          code: "067",
        },
        {
          name: "BANK WOORI INDONESIA",
          code: "068",
        },
        {
          name: "BANK OF CHINA LIMITED",
          code: "069",
        },
        {
          name: "BANK BUMI ARTA",
          code: "076",
        },
        {
          name: "BANK EKONOMI",
          code: "087",
        },
        {
          name: "BANK ANTARDAERAH",
          code: "088",
        },
        {
          name: "BANK HAGA",
          code: "089",
        },
        {
          name: "BANK IFI",
          code: "093",
        },
        {
          name: "BANK CENTURY, TBK.",
          code: "095",
        },
        {
          name: "BANK MAYAPADA",
          code: "097",
        },
        {
          name: "BANK JABAR",
          code: "110",
        },
        {
          name: "BANK DKI",
          code: "111",
        },
        {
          name: "BPD DIY",
          code: "112",
        },
        {
          name: "BANK JATENG",
          code: "113",
        },
        {
          name: "BANK JATIM",
          code: "114",
        },
        {
          name: "BPD JAMBI",
          code: "115",
        },
        {
          name: "BPD ACEH",
          code: "116",
        },
        {
          name: "BANK SUMUT",
          code: "117",
        },
        {
          name: "BANK NAGARI",
          code: "118",
        },
        {
          name: "BANK RIAU",
          code: "119",
        },
        {
          name: "BANK SUMSEL",
          code: "120",
        },
        {
          name: "BANK LAMPUNG",
          code: "121",
        },
        {
          name: "BPD KALSEL",
          code: "122",
        },
        {
          name: "BPD KALIMANTAN BARAT",
          code: "123",
        },
        {
          name: "BPD KALTIM",
          code: "124",
        },
        {
          name: "BPD KALTENG",
          code: "125",
        },
        {
          name: "BPD SULSEL",
          code: "126",
        },
        {
          name: "BANK SULUT",
          code: "127",
        },
        {
          name: "BPD NTB",
          code: "128",
        },
        {
          name: "BPD BALI",
          code: "129",
        },
        {
          name: "BANK NTT",
          code: "130",
        },
        {
          name: "BANK MALUKU",
          code: "131",
        },
        {
          name: "BPD PAPUA",
          code: "132",
        },
        {
          name: "BANK BENGKULU",
          code: "133",
        },
        {
          name: "BPD SULAWESI TENGAH",
          code: "134",
        },
        {
          name: "BANK SULTRA",
          code: "135",
        },
        {
          name: "BANK NUSANTARA PARAHYANGAN",
          code: "145",
        },
        {
          name: "BANK SWADESI",
          code: "146",
        },
        {
          name: "BANK MUAMALAT",
          code: "147",
        },
        {
          name: "BANK MESTIKA",
          code: "151",
        },
        {
          name: "BANK METRO EXPRESS",
          code: "152",
        },
        {
          name: "BANK SHINTA INDONESIA",
          code: "153",
        },
        {
          name: "BANK MASPION",
          code: "157",
        },
        {
          name: "BANK HAGAKITA",
          code: "159",
        },
        {
          name: "BANK GANESHA",
          code: "161",
        },
        {
          name: "BANK WINDU KENTJANA",
          code: "162",
        },
        {
          name: "HALIM INDONESIA BANK",
          code: "164",
        },
        {
          name: "BANK HARMONI INTERNATIONAL",
          code: "166",
        },
        {
          name: "BANK KESAWAN",
          code: "167",
        },
        {
          name: "BANK TABUNGAN NEGARA (PERSERO)",
          code: "200",
        },
        {
          name: "BANK HIMPUNAN SAUDARA 1906, TBK .",
          code: "212",
        },
        {
          name: "BANK TABUNGAN PENSIUNAN NASIONAL",
          code: "213",
        },
        {
          name: "BANK SWAGUNA",
          code: "405",
        },
        {
          name: "BANK JASA ARTA",
          code: "422",
        },
        {
          name: "BANK MEGA",
          code: "426",
        },
        {
          name: "BANK JASA JAKARTA",
          code: "427",
        },
        {
          name: "BANK BUKOPIN",
          code: "441",
        },
        {
          name: "BANK SYARIAH MANDIRI",
          code: "451",
        },
        {
          name: "BANK BISNIS INTERNASIONAL",
          code: "459",
        },
        {
          name: "BANK SRI PARTHA",
          code: "466",
        },
        {
          name: "BANK JASA JAKARTA",
          code: "472",
        },
        {
          name: "BANK BINTANG MANUNGGAL",
          code: "484",
        },
        {
          name: "BANK BUMIPUTERA",
          code: "485",
        },
        {
          name: "BANK YUDHA BHAKTI",
          code: "490",
        },
        {
          name: "BANK MITRANIAGA",
          code: "491",
        },
        {
          name: "BANK AGRO NIAGA",
          code: "494",
        },
        {
          name: "BANK INDOMONEX",
          code: "498",
        },
        {
          name: "BANK ROYAL INDONESIA",
          code: "501",
        },
        {
          name: "BANK ALFINDO",
          code: "503",
        },
        {
          name: "BANK SYARIAH MEGA",
          code: "506",
        },
        {
          name: "BANK INA PERDANA",
          code: "513",
        },
        {
          name: "BANK HARFA",
          code: "517",
        },
        {
          name: "PRIMA MASTER BANK",
          code: "520",
        },
        {
          name: "BANK PERSYARIKATAN INDONESIA",
          code: "521",
        },
        {
          name: "BANK AKITA",
          code: "525",
        },
        {
          name: "LIMAN INTERNATIONAL BANK",
          code: "526",
        },
        {
          name: "ANGLOMAS INTERNASIONAL BANK",
          code: "531",
        },
        {
          name: "BANK DIPO INTERNATIONAL",
          code: "523",
        },
        {
          name: "BANK KESEJAHTERAAN EKONOMI",
          code: "535",
        },
        {
          name: "BANK UIB",
          code: "536",
        },
        {
          name: "BANK ARTOS IND",
          code: "542",
        },
        {
          name: "BANK PURBA DANARTA",
          code: "547",
        },
        {
          name: "BANK MULTI ARTA SENTOSA",
          code: "548",
        },
        {
          name: "BANK MAYORA",
          code: "553",
        },
        {
          name: "BANK INDEX SELINDO",
          code: "555",
        },
        {
          name: "BANK VICTORIA INTERNATIONAL",
          code: "566",
        },
        {
          name: "BANK EKSEKUTIF",
          code: "558",
        },
        {
          name: "CENTRATAMA NASIONAL BANK",
          code: "559",
        },
        {
          name: "BANK FAMA INTERNASIONAL",
          code: "562",
        },
        {
          name: "BANK SINAR HARAPAN BALI",
          code: "564",
        },
        {
          name: "BANK HARDA",
          code: "567",
        },
        {
          name: "BANK FINCONESIA",
          code: "945",
        },
        {
          name: "BANK MERINCORP",
          code: "946",
        },
        {
          name: "BANK MAYBANK INDOCORP",
          code: "947",
        },
        {
          name: "BANK OCBC – INDONESIA",
          code: "948",
        },
        {
          name: "BANK CHINA TRUST INDONESIA",
          code: "949",
        },  
        {
          name: "BANK COMMONWEALTH",
          code: "950",
        },
        {
          name: "BANK BJB SYARIAH",
          code: "425",
        },
        {
          name: "BPR KS (KARYAJATNIKA SEDAYA)",
          code: "688",
        },
      ],

      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
      provId: null,
      model_bank:{}
    };
  },
  mounted() {
    let _ = this;
    _.getProvince();

    setTimeout(() => {
      _.getData();
    }, 2000);
    _.getBank()
    _.getSocialmedia()
  },
  methods: {
    notTheseDay(date)
    {
      let year_now = new Date().getFullYear()
      let year = year_now - 17

      let d = new Date();
      let set = d.setFullYear(year);

      // return date < set || date > new Date();
      return date > set
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    sanitizeNPWP() {
        let _ = this
        let npwp;
        npwp = _.model.npwp.replaceAll('.','')
        npwp = npwp.replaceAll('-','')

        return npwp
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;

            _.model = response.data.data;
            _.model.email = _.model.account.email;
            _.model.phoneNumber = _.model.account.phoneNumber;
            var str = _.model.dateOfBirth;
            var res = str.split("-");

            // console.log(res[res.length - 1], res[res.length - 2], res[res.length - 3]);

            _.model.dateOfBirth = new Date(
              res[res.length - 3],
              res[res.length - 2] - 1,
              res[res.length - 1]
            );

            // console.log(_.model.dateOfBirth);

            let tempProvince = this.options_province;

            tempProvince = tempProvince.filter((item) => {
              return item.name == _.model.province;
            });

            this.provId = tempProvince[0].id;

            // let ig = _.model.socialMediaProfiles;
            // ig = ig.filter((item) => {
            //   return item.type == "instagram";
            // });
            // _.model.instagram = ig[0].url;

            // let fb = _.model.socialMediaProfiles;
            // fb = fb.filter((item) => {
            //   return item.type == "facebook";
            // });
            // _.model.facebook = fb[0].url;

            // let yt = _.model.socialMediaProfiles;
            // yt = yt.filter((item) => {
            //   return item.type == "youtube";
            // });
            // _.model.youtube = yt[0].url;
          });
      }
    },
    getProvinceId() {
      let _ = this;
      this.model.city = null
      let tempProvince = this.options_province;
      tempProvince = tempProvince.filter((item) => {
        return item.name == _.model.province;
      });
      this.provId = tempProvince[0].id;
    },

    getProvince() {
      let _ = this;
      axios.get(_.config.province).then((response) => {
        let _ = this;
        _.options_province = response.data.data;
      });
    },

    getBank() {
      let _ = this;
      axios.get(_.config.bank + this.$route.params.id).then((response) => {
        let _ = this;
        _.model_bank = response.data.data;
      });
    },

    getCity() {
      let _ = this;
      axios.get(_.config.city + "/" + _.provId).then((response) => {
        let _ = this;
        _.options_city = response.data.data;
      });
    },

    save() {
      let _ = this;
      _.model.id = _.$route.params.id;

      let data = {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        email: this.model.email,
        phoneNumber: this.model.phoneNumber,
        address: this.model.address,
        province: this.model.province,
        gender: this.model.gender,
        city: this.model.city,
        age: this.model.age,
        dateOfBirth: this.model.dateOfBirth,
        npwp : this.sanitizeNPWP(),  
        socialMedia: [
          {
            type: "instagram",
            url: this.model.instagram,
          },
          {
            type: "facebook",
            url: this.model.facebook,
          },
          {
            type: "youtube",
            url: this.model.youtube,
          },
        ],
      };
      
      axios
        .put(_.config.api + "/" + _.$route.params.id, data)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },

    saveRekening() {
      let _ = this;
      _.model.id = _.$route.params.id;

      let data = {
        bank: this.model_bank.bank,
        bankAccountName: this.model_bank.bankAccountName,
        bankAccountNumber: this.model_bank.bankAccountNumber,
      };

      axios
        .put(_.config.update_bank + _.$route.params.id, data)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },

    instagramReset(id) {      
      let platform  = id == 1 ? 'instagram' : "facebook";

      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk reset akun sosial media Instagram dan Facebook user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/digirans/influencer-managements/reset-socmed/"  +   this.$route.params.id + "/" + platform) 
            .then((response) => {
              this.getSocialmedia()
              this.$swal({
                icon: "success",
                title: "Sukses",
                text: "Reset akun Instagram dan Facebook berhasil",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              
            });
        }
      });
    },

    getSocialmedia(){
      let _ = this;
      axios.get(_.config.socmed + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        let data = response.data.data;
            let data_ig = data.filter((item) => {
              return item.type == "instagram";
            });
            _.listSocmed.instagram = data_ig.length > 0 ? data_ig[0].username : null;

            let data_fb = data.filter((item) => {
              return item.type == "facebook";
            });
            
            _.listSocmed.facebook = data_fb.length > 0 ? data_fb[0].username : null
            

            let data_yt = data.filter((item) => {
              return item.type == "youtube";
            });
            
            _.listSocmed.youtube = data_yt.length > 0 ? data_yt[0].username : null;
      });
    },

    youtubeReset() {      
      let platform  = "youtube";
      
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk reset akun Youtube user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/digirans/influencer-managements/reset-socmed/"  +   this.$route.params.id + "/" + platform)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Sukses",
                text: "Reset akun Youtube berhasil",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.getSocialmedia()
            });
        }
      });
    },
  },
  watch: {
    provId: function (newVal, oldVal) {
      this.getCity();
    },
    "model.dateOfBirth": function (newVal, oldVal) {
      var y = newVal.getFullYear();
      var m = newVal.getMonth();
      var d = newVal.getDate();
      var dob = new Date(m + "/" + d + "/" + y);
      var month_diff = Date.now() - dob.getTime();
      //convert the calculated difference in date format
      var age_dt = new Date(month_diff);

      //extract year from date
      var year = age_dt.getUTCFullYear();

      //now calculate the age of the user
      var age = Math.abs(year - 1970);

      this.model.age = age;
    },
  },
};
</script>
