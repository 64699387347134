<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="12" class="text-left">
          <b-row>
            <b-col md="3">
              <h5
                class="text-center p-1 text-capitalize"
                style="border: 2px solid #e83e8c; border-radius: 2px"
              >
                {{ model.status }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="6">
              <h4>Informasi Advertiser</h4>
              <b-row>
                <b-col md="6">
                  <p>Advertiser</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.advertiser.companyName }}</p>
                </b-col>
                <b-col md="6">
                  <p>Status Advertiser</p>
                </b-col>
                <b-col md="6">
                  <p class="text-capitalize">
                    : {{ model.advertiser.account.verified == true ? "Verified" : "Not Verified" }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr />

          <b-row class="mt-2">
            <b-col md="12">
              <h4>Informasi Campaign</h4>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="6">
                  <p>Nama Campaign</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.name }}</p>
                </b-col>
                <b-col md="6">
                  <p>Order ID</p>
                </b-col>
                <b-col md="6">
                  <p>: {{model.orderID}}</p>
                </b-col>
                <b-col md="6">
                  <p>Jenis Campaign</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.campaignType }}</p>
                </b-col>
                <b-col md="6">
                  <p>Platform</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.platformType ? model.platformType.name : "-" }}</p>
                </b-col>
                <b-col md="6">
                  <p>Layanan</p>
                </b-col>
                <b-col md="6" >
                  <!-- <p>
                    : {{ model.services[0] ? model.services[0].name : "-" }}
                  </p> -->
                  :
                   <span v-for="(item, index) in model.services" :key="index">
                     {{ item.name }}
                  </span>
                </b-col>
                <b-col md="6">
                  <p>Jumlah Post</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.postAmount != null ? model.postAmount : 0  }}</p>
                </b-col>
                <b-col md="6">
                  <p>Harga Proyek</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.cost | currency }}</p>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <b-row>
                <b-col md="6">
                  <p>Tanggal Campaign Mulai</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.startDate_cast }}</p>
                </b-col>
                <b-col md="6">
                  <p>Tanggal Campaign Selesai</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.endDate_cast }}</p>
                </b-col>
                <b-col md="6">
                  <p>Tanggal Deadline Konten</p>
                </b-col>
                <b-col md="6">
                  <p>: {{ model.contentDeadline_cast }}</p>
                </b-col>
                <b-col md="6">
                  <p>Status Penayangan</p>
                </b-col>
                <b-col md="6">
                  <p class="text-capitalize">: {{ model.status }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs pills>
        <b-tab title="Informasi Influencer" active>
          <b-col md="12">
            <h5>Informasi Campaign</h5>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6">
                <p>Cari Nama Influencer</p>
              </b-col>
              <b-col md="6">
                <p>: {{ model.influencer && model.influencer.fullName }}</p>
              </b-col>
              <b-col md="6">
                <p>Status influencer</p>
              </b-col>
              <b-col md="6">
                <p class="text-capitalize">
                  : {{ model.influencer && model.influencer.account.verified == true ? "Verified" : "Not Verfied"  }}
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-tab>
        <b-tab title="Upload Konten">
          <b-row>
            <b-col md="12">
              <h5>Upload Konten Google Drive</h5>
            </b-col>
            <b-col v-if="detail.length == 0">
              <h5 class="text-center">Belum ada konten</h5>
            </b-col>
            <b-col
              v-else
              md="12"
              v-for="(i, index) in detail"
              :key="index"
              class="p-1 mt-1"
              style="border: 2px solid #e83e8c; border-radius: 5px"
            >
              <b-row>
                <b-col md="6">
                  
                </b-col>
                <b-col md="6">
                  
                </b-col>
                <b-col md="9">
                  <b-row>
                    <b-col md="3">
                      <p>Tanggal</p>
                    </b-col>
                    <b-col md="9">
                      <p>: {{ detail[index].createdAt }}</p>
                    </b-col>
                    <b-col md="3">
                      <p>Link Konten</p>
                    </b-col>
                    <b-col md="9">
                      <p>: {{ detail[index].url }}</p>                      
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col md="12">
                      <h6
                        class="text-center p-1 text-capitalize"
                        style="border: 2px solid #e83e8c; border-radius: 2px"
                      >
                        {{ detail[index].status }}
                      </h6>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Insight">
          <b-row>
            <b-col md="12">
              <h5>Show Insight</h5>
              <p>Platform {{model.platformType ? model.platformType.name : "-"}}</p>
              <b-tabs pills justified>
                <b-tab  v-for="(i, index) in model.services" :key="index" :title="i.name" @click="changeService(i.id)">
                  <b-row>
                    <b-col v-if="contentData.length == 0">
                      <h4 class="text-center">Belum ada konten</h4>
                    </b-col>
                    <b-col
                      v-else
                      md="12"
                      v-for="(i, index) in contentData"
                      :key="index"
                      class="p-1 mt-1"
                      style="border: 2px solid #e83e8c; border-radius: 5px"
                    >
                      <b-row>
                        <b-col md="12">
                          <h5>Post Ke {{ index + 1 }}</h5>
                        </b-col>
                        <b-col md="6">
                          <b-row>
                            <b-col md="12">
                              <p>Media : {{i.url}}</p>
                            </b-col>

                            <b-col md="12" v-if="i.serviceID !== 1">
                              <p>Caption : {{i.caption}}</p>
                            </b-col>

                            <b-col md="6">
                              <p>Comment : {{i.comment}}</p>
                            </b-col>

                            <b-col md="6" v-if="i.serviceID !== 1">
                              <p>Likes : {{i.like}}</p>
                            </b-col>

                            <b-col md="6" v-if="i.serviceID !== 1">
                              <p v-if="i.serviceID != 6 && i.serviceID != 7">Engagement : {{i.engagement}}</p>
                              <p v-if="i.serviceID == 6 || i.serviceID == 7">Total Views : {{i.engagement}}</p>
                            </b-col>

                            <b-col md="6" v-if="i.serviceID !== 6 && i.serviceID != 7">
                              <p>Impressions : {{i.impression}}</p>
                            </b-col>

                            <!-- <b-col md="6">
                              <p>Interaksi : 0</p>
                            </b-col> -->

                            <b-col md="6" v-if="i.serviceID !== 6 && i.serviceID != 7">
                              <p>Reach : {{i.reach}}</p>
                            </b-col>

                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Review">
          <div v-if="reviews.length > 0">
            <b-row
            v-for="(i, index) in reviews" :key="index"
            style="border: 2px solid #e83e8c; border-radius: 10px"
            class="mt-2 ml-1 mr-1"
          >
            <b-col md="2" class="mt-1 mb-1">
              <!-- media -->
              <b-media no-body>
                <b-media-aside>
                  <!-- <b-link>
                    <b-img
                      ref="previewEl"
                      rounded="circle"
                      src="https://picsum.photos/1024/400/?image=41"
                      class=""
                      height="65"
                      width="65"
                    />
                  </b-link> -->
                  <b-avatar :src="i.advertiser && i.advertiser.account.avatar ? i.advertiser.account.avatar : null" class="mb-1" size="90px"></b-avatar>
                </b-media-aside>
              </b-media>
              <!--/ media  :src="'https://api-digirans.demoapp.xyz/' + i.campaign.banner" -->
            </b-col>
            <b-col md="8" class="text-left mt-1">
              <b-row>
                <b-col md="12">
                  <h5 class="mb-0"><b>{{i.advertiser.companyName}}</b></h5>
                </b-col>
                <b-col md="12">
                  <b-form-rating
                    no-border
                    v-model="i.rating"
                    readonly
                    variant="primary"
                    inline
                    size="sm"
                    precision="2"
                    style="margin-left: -20px; font-size: 20px"
                  />
                </b-col>
                <b-col>
                  <p>{{i.message}}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          </div>
          <div v-else>
            <b-row>
              <b-col md=12>
                <h5 class="text-center">Belum ada ulasan</h5>
              </b-col>
            </b-row>
            
          </div>  
          
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'campaigns' }"
      >
        Kembali
      </b-button>
    </b-card-footer>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BFormRating,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    BFormRating,
    BAvatar,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      config: {
        api: "digirans/campaign-managements",
        content: "digirans/campaign-managements/content",
        redirect: "campaigns",
        review: "digirans/campaign-managements/review",
        service_type: "misc/types/services/",
        service_content:"misc/social-media/content-posts/"
      },
      platformDataService: {},
      contentData:{},
      model: {
        advertiser: {
          account: {},
        },
        platformType: {},
        services: [
          {
            name: "",
          },
        ],
      },
      detail: {},
      reviews:[],
      sample_review:[
        {
          "rating" : 3, 
          "companyName" : "Zalora",
          "message" : "Sangat Baik"
        },
        {
          "rating" : 5, 
          "companyName" : "Zalora 2",
          "message" : "Sangat Baik"
        },
      ]
    };
  },
  created() {
    let _ = this;
    _.getData();
    _.getContent();
    _.getReview()
  },
  methods: {
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data.data;
        this.getServicePLatform(_.model.platformTypeID);

      });
    },
    getContent() {
      let _ = this;
      axios
        .get(_.config.content + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.detail = response.data.data.data;
          console.log(_.detail);
        });
    },
    getReview() {
      let _ = this;
      axios
        .get(_.config.review + "/" + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.reviews = response.data.data.data;
        });
    },
    getServicePLatform(id) {
      let _ = this;
      axios
        .get(_.config.service_type + "/" + id)
        .then((response) => {
          let _ = this;
          _.platformDataService = response.data.data;
            console.log(_.platformDataService[0].id);
            let id = _.platformDataService[0].id;
            this.changeService(id)
        });
    },
    changeService(id)
    {
      let _ = this;
      axios
        .get(_.config.service_content + "/" + this.$route.params.id +"/"+ id)
        .then((response) => {
          let _ = this;
          _.contentData = response.data.data;
          console.log(_.contentData);
        });
    }
  },

  watch: {
    'model.services': function (newVal, oldVal) {
      // console.log(newVal.length, "ini Length");
      if (newVal.length > 0) {
        this.changeService(newVal[0].id)           
      }
    }
  },
};
</script>
