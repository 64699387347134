<template>
  <b-card :title="this.$route.meta.title">
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="image_tmb"
            rounded
            :src=model.banner
            height="120"
            width="180"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.file.$el.click()"
        >
          Ubah Cover
        </b-button>
        <b-form-file
          ref="file"
          @input="clickref"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
        />
        <!--/ upload button -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2Mb</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(save)" class="mt-2">
        <b-row>
          <b-col md="6">
            <ValidationProvider
              name="Nama Campaign"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Nama Campaign" label-for="mc-name">
                <b-form-input
                  id="mc-name"
                  placeholder="Nama Campaign"
                  v-model="model.name"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Tanggal Campaign Mulai"
              rules="required"
              v-slot="{ errors }"
            >
              <!-- :disabled-date="notAfterToday" -->
              <b-form-group label="Tanggal Campaign Mulai" label-for="mc-tanggal-mulai">
                <date-picker
                  :firstDayOfWeek="1"
                  v-model="model.startDate"
                  name="tanggal_mulai"
                  :lang="lang"
                  :format=formats
                ></date-picker>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Tanggal Campaign Selesai"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Tanggal Campaign Selesai"
                label-for="mc-tanggal-selesai"
              >
                <date-picker
                  :firstDayOfWeek="1"
                  v-model="model.endDate"
                  name="tanggal_selesai"
                  :lang="lang"
                  :format=formats
                ></date-picker>
                <i>Tips : Berikan waktu lebih jika campaign kamu memerlukan pengiriman barang ke influencer</i>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <ValidationProvider
              name="Tanggal Deadline"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Tanggal Deadline"
                label-for="mc-tanggal-deadline"
              >
                <date-picker
                  :firstDayOfWeek="1"
                  v-model="model.contentDeadline"
                  name="tanggal_deadline"
                  :lang="lang"
                  :format=formats
                ></date-picker>
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <label for="textarea-auto-height">Brief/Deskripsi Campaign</label>
            <b-form-textarea
              id="textarea-auto-height"
              placeholder="Tulis brief campaign kamu untuk influencer dengan jelas"
              rows="3"
              v-model="model.description"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-col>

          <b-col md="12">
            <ValidationProvider
              name="Tipe Konten"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group label="Tipe Konten" label-for="mc-tipe-konten">
                <b-form-radio-group
                  v-model="model.contentType"
                  :options="typeContent"
                  class="mt-1"
                  value-field="name"
                  text-field="name"
                />
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12">
            <ValidationProvider
              name="Send Item"
              rules="required"
              v-slot="{ errors }"
            >
              <b-form-group
                label="Apakah Kamu memerlukan kirim barang ke influencer?"
                label-for="mc-sample"
              >
                <b-form-radio-group
                  v-model="model.sample"
                  :options="typeSample"
                  class="mt-1"
                  value-field="value"
                  text-field="name"
                />
                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="12" v-if="model.sample == true">
            <ValidationProvider
              name="No Resi"
              rules=""
              v-slot="{ errors }"
            >
              <b-form-group label="No Resi" label-for="mc-no-resi">
                <b-form-input
                  id="mc-no-resi"
                  placeholder="No Resi"
                  v-model="model.receiptNumber"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>

          <b-col md="6">
            <label for="textarea-auto-height">Aturan Foto/Video</label>
            <b-form-textarea
              id="textarea-auto-height1"
              placeholder="Tulis aturan-aturan foto/video untuk  Campaign kamu"
              rows="3"
              v-model="model.mediaGuideline"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-col>

          <b-col md="6">
            <label for="textarea-auto-height">Aturan Caption (Opsional)</label>
            <b-form-textarea
              id="textarea-auto-height2"
              placeholder="Tulis aturan caption atau arahan untuk membuat caption. Contoh menggunakan # , dsb."
              rows="3"
              v-model="model.captionGuideline"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-col>

          <b-col md="12">
            <label for="textarea-auto-height3">Informasi Lainnya (Opsional)</label>
            <b-form-textarea
              id="textarea-auto-height"
              placeholder="Tulis informasi lainnya sebagai pendukung"
              rows="3"
              v-model="model.additionalInfo"
              max-rows="8"
              class="mb-1 mb-xl-0"
            />
          </b-col>

          

          <b-col md="12" class="mt-1 mb-0">
            <p>Referensi Foto/Video</p>
          </b-col>

          <b-col md="12">
              <ValidationProvider
              name="Refrence"
              rules=""
              v-slot="{ errors }"
            >
              <b-form-group label="Foto / Video (URL)" label-for="mc-url">
                <b-form-input
                  id="mc-url"
                  placeholder="Masukkan URL"
                  v-model="model.reference"
                />

                <p class="fs-6 text-danger">
                  {{ errors[0] }}
                </p>
              </b-form-group>
            </ValidationProvider>
          </b-col>


        </b-row>
        <b-card-footer>
          <!-- submit and reset -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1 float-right"
          >
            Simpan
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1 float-right"
            variant="outline-secondary"
            :to="{ name: 'campaigns' }"
          >
            Batal
          </b-button>
        </b-card-footer>
      </b-form>
    </ValidationObserver>
  </b-card>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      options_city: [
        {
          value: "Bandung",
          name: "Bandung",
        },
        {
          value: "Samarinda",
          name: "Samarinda",
        },
      ],
      name: "InfluencerForms",
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: "digirans/campaign-managements",
        redirect: "campaigns",
      },
      model: {
        banner:null,
        reference:null,
        startDate:null,
        endDate:null,
        contentDeadline:null,
        name:null,
        description:null,
        contentType:null,
        additionalInfo:null,
        captionGuideline:null,
        mediaGuideline:null,
        sample:null,
        receiptNumber:null,
      },
      messages: "",
      typeContent: [
        { id: 1, name: "Review" },
        { id: 2, name: "Video di Lokasi" },
        { id: 3, name: "Video dengan Produk" },
        { id: 3, name: "Lainnya" },
      ],

      typeSample: [
        { name: "Ya", value: true },
        { name: "Tidak", value: false },
      ],

      formats: "DD/MM/YYYY",
      reference:null,
      files:null,
      img_tamp:null,
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    };
  },
  mounted() {
    let _ = this;
    _.getData();
  },
  methods: {
    clickref()
    {
      this.model.banner =  this.$refs.file.files[0]
      let reader = new FileReader();
      reader.onload = (e) => {
          this.$refs.image_tmb.src = reader.result;
      };
      reader.readAsDataURL(this.model.banner);
      this.$forceUpdate()
      // this.$refs.file.$el.click()
      // this.model.banner = URL.createObjectURL(img);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;

            _.model = response.data.data;

            var str = _.model.startDate;
            var res = str.split("-");
            _.model.startDate = new Date(
              res[res.length - 3],
              res[res.length - 2] - 1,
              res[res.length - 1]
            );

            var str1 = _.model.endDate;
            var res1 = str1.split("-");
            _.model.endDate = new Date(
              res1[res1.length - 3],
              res1[res1.length - 2] - 1,
              res1[res1.length - 1]
            );

            var str2 = _.model.contentDeadline;
            var res2 = str2.split("-");
            _.model.contentDeadline = new Date(
              res2[res2.length - 3],
              res2[res2.length - 2] - 1,
              res2[res2.length - 1]
            );

            
          });
      }
    },

    save() {
      let _ = this;
      _.model.id = _.$route.params.id;
      let formData = new FormData();

      formData.append('id', _.model.id)
      formData.append('banner', this.$refs.file.files[0] != undefined ? this.$refs.file.files[0] : this.model.banner)
      formData.append('name', this.model.name)
      formData.append('startDate', this.model.startDate)
      formData.append('endDate', this.model.endDate)
      formData.append('contentDeadline', this.model.contentDeadline)
      formData.append('description', this.model.description)
      formData.append('contentType', this.model.contentType)
      formData.append('sample', this.model.sample)
      formData.append('receiptNumber', this.model.sample == true ? this.model.receiptNumber : '')
      formData.append('mediaGuideline', this.model.mediaGuideline)
      formData.append('additionalInfo ', this.model.additionalInfo)
      formData.append('captionGuideline', this.model.captionGuideline)
      formData.append('reference', this.model.reference)
    
      axios
        .put(_.config.api + "/" + _.$route.params.id, formData)
        .then((res) => {
          _.$router.push({
            name: _.config.redirect,
            params: {
              variant: "success",
              message: res.data.message,
            },
          });
        })
        .catch((e) => {
          let vm = this;
          vm.showDismissibleAlert = true;
          if (typeof e.response.data.message === "object") {
            vm.messages = e.response.data.message.errors[0].msg;
          } else {
            vm.messages = e.response.data.message;
          }
        });
    },
  },
  watch: {
    'model.banner': function (newVal, oldVal) {
      // this.$refs.file.$el.click()
      // let reader = new FileReader();
      // reader.onload = (e) => {
      //     this.$refs.image_tmb.src = reader.result;
      // };
      // reader.readAsDataURL(this.model.banner);
    },
  },

  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
};
</script>
