<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <b-img
          src="@/assets/images/logo/new_logo_2.png"
          alt="logo"
          width="200px;"
        />
        <!-- <h2 class="brand-text text-primary ml-1">
          Digirans
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Lupa Password? 
          </b-card-title>
          <b-card-text class="mb-2">
            Jangan khawatir, masukkan email untuk dikirimkan kode OTP sebagai verifikasi membuat password baru 
          </b-card-text>
          <!-- <b-alert variant="danger" :show="this.response.message != null">
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"
                  ><span class="font-weight-bold">{{
                    this.response.message
                  }}</span></small
                >
              </p>
            </div>
          </b-alert> -->
          <!-- form -->
            <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              class="auth-forgot-password-form mt-2"
               @submit.prevent="handleSubmit(resetpassword)"
            >
              <b-form-group
                label="Masukkan Email"
                label-for="forgot-password-email"
              >
                 <ValidationProvider
                  name="Alamat Email"
                  rules='required|email'
                  v-slot="{ errors }"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="model.email"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="Masukkan Alamat Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                 </ValidationProvider>
              </b-form-group>
              
              <b-button
                type="submit"
                variant="primary"
                :disabled="model.email == ''"
                block
              > 
                Kirim Link
                <b-spinner v-show="loading == true" small class="ml-2"/>
              </b-button>

            </b-form>
            </ValidationObserver>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Kembali ke Login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BAlert, BSpinner
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import axios from "@axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BSpinner
  },
  data() {
    return {
      userEmail: '',
      config:{
        api:'/account/forgot-password'
      },
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      response: {
        message: "Sample Error Message",
      },
      model:{
        email:''
      },
      loading:false

    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    resetpassword()
    {
        let _ = this
        axios.post(_.config.api, {email:_.model.email})
        .then(res => {
            if (res.data) {
              localStorage.setItem('reset_token', res.data.data.resetPassToken)
            }
            this.loading = true
            _.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
          setTimeout(() => {
            window.location = '/verification-forgot-password'
          }, 5000);
        }).catch(e => {
            console.log(e.response.data.message);
            _.$toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
