<template>
  <div class="card">
    <b-card :title="this.$route.meta.title">
      <b-row>
        <b-col md="2">
          <!-- media -->
          <b-media no-body>
            <b-media-aside>
              <b-link>
                <!-- <b-img
                  ref="previewEl"
                  rounded="circle"
                  :src=" model.account.avatar !=null  ?  model.account.avatar : 'https://picsum.photos/1024/400/?image=41'"
                  height="80"
                  width="80"
                  class="ml-3"
                /> -->
                <b-avatar :src="model.account.avatar" class="mb-1 ml-2" size="90px"></b-avatar>
              </b-link>
            </b-media-aside>
          </b-media>
          <!--/ media -->
        </b-col>
        <b-col md="10" class="text-left">
          <b-row>
            <b-col md="4">
              <p class="mb-0"><b>Nama Influencer</b></p>
              <p>
                {{ model.fullName }} &nbsp; &nbsp;
                <span v-if="model.account.verified == true"
                  ><b
                    ><feather-icon
                      class="ml-3"
                      width="20px"
                      height="20px;"
                      icon="CheckCircleIcon" /></b
                ></span>
              </p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Usia</b></p>
              <p>{{ model.age }}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Total Saldo Pendapatan</b></p>
              <p>{{ model.incomeBalance | currency}}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Jenis Kelamin</b></p>
              <p>{{ model.gender }}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Tipe Konten</b></p>
              <!-- <span v-for="(i, index) in model.contentTypes" :key="index">{{i.name }} &nbsp;</span>
              <span v-if="index != model.contentTypes.length - 1">, </span>   -->

              <span v-for="(i, index) in model.contentTypes" :key="index">
                {{ i.name }}
                <span v-if="index != model.contentTypes.length - 1">, </span>
              </span>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Total Saldo Ditarik</b></p>
              <p>{{ model.incomeWithdraw | currency}}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Email</b></p>
              <p>{{ model.account.email }}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Kota/Kabupaten</b></p>
              <p>{{ model.city }}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Sisa Saldo</b></p>
              <p>{{ (model.incomeBalance - model.incomeWithdraw) | currency}}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>No Telepon</b></p>
              <p>{{ model.account.phoneNumber }}</p>
            </b-col>

            <b-col md="4">
              <p class="mb-0"><b>Alamat</b></p>
              <p>{{ model.address }}</p>
            </b-col>

            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-left"
                :to="{ name: 'influencers-review' }"
              >
                Review
              </b-button>
            </b-col>
             <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="float-left"
                @click="gotoShowPage()"
              >
                Rekening
              </b-button>
            </b-col>
            <b-col md="4">
              <p class="mb-0"><b>NPWP</b></p>
              <p>{{ npwp_display }}</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs pills>
        <b-tab title="Instagram" @click="changeUrl(1)" active>
          <b-row class="m-2" v-if="instagram_detail.success == false">
            <b-col md="12">
              <h4>{{ instagram_detail.message }}</h4>
            </b-col>
          </b-row>
          <b-row class="m-2" v-else>
            <b-col md="12">
              <h5>
                <span
                  ><b
                    ><feather-icon
                      width="16px"
                      height="16px;"
                      icon="InstagramIcon"
                    />
                    &nbsp; {{ instagram_detail.profile.username }}
                  </b></span
                >
              </h5>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Post</b></p>
              <p>{{ instagram_detail.profile.posts }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Followers</b></p>
              <p>{{ instagram_detail.profile.followers }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Following</b></p>
              <p>{{ instagram_detail.profile.following }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Engagement Rate</b></p>
              <p>{{instagram_detail.insights.engagement}}</p>
            </b-col>

            <b-col
              md="6"
              v-for="(i, index) in instagram_detail.pricings"
              :key="index"
            >
              <b-card
                class="text-left"
                style="border: 2px solid #e83e8c; border-radius: 10px"
              >
                <p class="mb-0">
                  <b>{{ i.service.name }}</b>
                </p>
                <b-row class="text-center mt-1">
                  <b-col md="6">
                    <p class="mb-0"><b>Harga</b></p>
                    <p>{{ i.price | currency }}</p>
                  </b-col>
                  <b-col md="6">
                    <p class="mb-0"><b>Impression</b></p>
                    <p>{{instagram_detail.insights.impression}}</p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Facebook" @click="changeUrl(2)">
           <b-row class="m-2" v-if="facebook_detail.success == false">
            <b-col md="12">
              <h4>{{ facebook_detail.message }}</h4>
            </b-col>
          </b-row>
          <b-row class="m-2" v-else>
            <b-col md="12">
              <h5>
                <span
                  ><b
                    ><feather-icon
                      width="16px"
                      height="16px;"
                      icon="FacebookIcon"
                    />
                    &nbsp; {{ facebook_detail.profile.username }}
                  </b></span
                >
              </h5>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Post</b></p>
              <p>{{  facebook_detail.profile.posts }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Friends</b></p>
              <p>{{ facebook_detail.profile.followers }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Likes</b></p>
              <p>{{ facebook_detail.profile.likes }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Engagement Rate</b></p>
              <p>{{facebook_detail.insights.engagement}}</p>
            </b-col>

            <b-col
              md="6"
              v-for="(i, index) in facebook_detail.pricings"
              :key="index"
            >
              <b-card
                class="text-left"
                style="border: 2px solid #e83e8c; border-radius: 10px"
              >
                <p class="mb-0">
                  <b>{{ i.service.name }}</b>
                </p>
                <b-row class="text-center mt-1">
                  <b-col md="6">
                    <p class="mb-0"><b>Harga</b></p>
                    <p>{{ i.price | currency }}</p>
                  </b-col>
                  <b-col md="6">
                    <p class="mb-0"><b>Impression</b></p>
                    <p>{{facebook_detail.insights.impression}}</p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Youtube" @click="changeUrl(3)">
          <b-row class="m-2" v-if="youtube_detail.success == false">
            <b-col md="12">
              <h4>{{ youtube_detail.message }}</h4>
            </b-col>
          </b-row>
             <b-row class="m-2" v-else>
            <b-col md="12">
              <h5>
                <span
                  ><b
                    ><feather-icon
                      width="16px"
                      height="16px;"
                      icon="YoutubeIcon"
                    />
                    &nbsp; {{ youtube_detail.profile.username }}
                  </b></span
                >
              </h5>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Subscriber</b></p>
              <p>{{ youtube_detail.profile.subscriber }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Video</b></p>
              <p>{{ youtube_detail.profile.video }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>View</b></p>
              <p>{{ youtube_detail.profile.view }}</p>
            </b-col>
            <b-col md="3">
              <p class="mb-0"><b>Engagement Rate</b></p>
              <p>{{youtube_detail.insights.engagement}}</p>
            </b-col>

            <b-col
              md="6"
              v-for="(i, index) in youtube_detail.pricings"
              :key="index"
            >
              <b-card
                class="text-left"
                style="border: 2px solid #e83e8c; border-radius: 10px"
              >
                <p class="mb-0">
                  <b>{{ i.service.name }}</b>
                </p>
                <b-row class="text-center mt-1">
                  <b-col md="6">
                    <p class="mb-0"><b>Harga</b></p>
                    <p>{{ i.price | currency }}</p>
                  </b-col>
                  <b-col md="6">
                    <p class="mb-0"><b>Impression</b></p>
                    <p>{{youtube_detail.insights.impression}}</p>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-card-footer>
      <!-- Back -->
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-danger"
        :to="{ name: 'influencers' }"
      >
        Kembali
      </b-button>
    </b-card-footer>

    <b-modal
      ref="my-modal-rekening"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      
      title="Detail Rekening"
    >
      <b-row>
        <b-col md="6" class="mb-1"> Nama Bank </b-col>
        <b-col md="6" class="mb-1"> : {{bank_account.bank}}</b-col>

        <b-col md="6" class="mb-1"> Nomor Rekening </b-col>
        <b-col md="6" class="mb-1"> : {{bank_account.bankAccountNumber}} </b-col>

        <b-col md="6" class="mb-1"> Atas Nama </b-col>
        <b-col md="6" class="mb-1"> : {{bank_account.bankAccountName}}  </b-col>

      </b-row>
    </b-modal>
  </div>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
  BLink,
  BImg,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
  BTab,
  BTabs,
  BModal,
  VBModal,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
    BLink,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
    DatePicker,
    vSelect,
    BTab,
    BTabs,
    BModal,
    BAvatar,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
    "b-modal": VBModal,
  },
  data() {
    return {
      name: "InfluencerForms",
      errors: {},
      config: {
        api: "digirans/influencer-managements",
        redirect: "influencers",
        instagram_url: "digirans/influencer-managements/instagram/",
        facebook_url: "digirans/influencer-managements/facebook/",
        youtube_url: "digirans/influencer-managements/youtube/",
      },
      model: {
        account:{}
      },
      bank_account:{},
      table_active: 1,
      instagram_detail: {
        profile: {}
      },
      facebook_detail: {
        profile: {}
      },
      youtube_detail: {
        profile: {}
      },
      npwp_display : null
    };
  },
  beforeMount() {
    let _ = this;
    _.getData();
    _.getInstagram();
    _.getFacebook();
    _.getYoutube();
  },
  methods: {
    changeUrl(val) {
      this.table_active = val;
      if (val == 1) {
        // this.getInstagram()
      } else if (val == 2) {
        // this.getFacebook()
      } else {
        // this.getYoutube()
      }
    },
    getData() {
      let _ = this;
      axios.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        let _ = this;
        _.model = response.data.data;
        if (_.model.npwp) {
          let spliting = _.model.npwp
          let s  = spliting.toString()
          let res = s[0]+s[1]+'.'+s[2]+s[3]+s[4]+'.'+ s[5]+s[6]+s[7]+'.'+s[8]+'-'+s[9]+s[10]+s[11]+'.'+s[12]+s[13]+s[14]
          _.npwp_display = res  
        }
        
      });
    },
    getInstagram() {
      let _ = this;
      axios
        .get(_.config.instagram_url + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.instagram_detail = response.data.data;
        })
        .catch((err) => {
          _.instagram_detail = err.response.data;
        });
    },
    getFacebook() {
      let _ = this;
      axios
        .get(_.config.facebook_url + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.facebook_detail = response.data.data;
        })
        .catch((err) => {
          _.facebook_detail = err.response.data;
        });
    },
    getYoutube() {
      let _ = this;
      axios
        .get(_.config.youtube_url + this.$route.params.id)
        .then((response) => {
          let _ = this;
          _.youtube_detail = response.data.data;
        })
        .catch((err) => {
          _.youtube_detail = err.response.data;
        });
    },

    gotoShowPage() {
      let _ = this;
      axios
        .get("digirans/influencer-managements/bank/" +  this.$route.params.id)
        .then((response) => {
          console.log(response);
          this.bank_account = response.data.data;
          console.log(this.bank_account);
        })
        .catch((err) => {
          console.log(err);
        });

      this.$refs["my-modal-rekening"].show();
    },
  },
};
</script>
