<template>
  <div>
    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Dashboard</b-card-title>
        <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col xl="4" sm="8">
            <b-media no-body>
              <b-media-aside class="">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{totalCampaign}}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Campaign
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col xl="4" sm="8">
            <b-media no-body>
              <b-media-aside class="">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{totalAdvertiser}}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Advertiser
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col xl="4" sm="8">
            <b-media no-body>
              <b-media-aside class="">
                <b-avatar size="48" variant="light-primary">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{totalInfluencer}}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Total Influencer
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card no-body class="card-statistics">
      <b-card-header>
        <b-card-title>Influencer Detail</b-card-title>
        <!-- <b-card-text class="font-small-2 mr-25 mb-0">
        Updated 1 month ago
      </b-card-text> -->
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col xl="6" sm="12" class="mb-4">
            <b-media no-body>
              <b-media-aside class="">
                <b-avatar size="48" variant="light-success">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{totalWeekly[0].total}}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Influencer per minggu
                  <p>Periode : {{totalWeekly[0].start}} - {{totalWeekly[0].end}}</p>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col xl="6" sm="12" class="mb-4">
            <b-media no-body>
              <b-media-aside class="">
                <b-avatar size="48" variant="light-info">
                  <feather-icon size="24" icon="UsersIcon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">{{totalMonthly[0].total}}</h4>
                <b-card-text class="font-small-3 mb-0">
                  Influencer per bulan
                  <p>Periode : {{totalMonthly[0].start}} - {{totalMonthly[0].end}}</p>
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col xl="6" sm="12" v-if="diagramshow == true">
            <h5 class="mb-2">Influencer Usia</h5>
            <vue-apex-charts
              height="220"
              ref="chart1"
              :options="chartOptions"
              :series="series"
              type="bar"
            />
          </b-col>
          <b-col xl="6" sm="12" v-if="diagramshow2 == true">
            <h5 class="mb-2">Influencer Jenis Kelamin</h5>
            <vue-apex-charts
              height="220"
              :options="earningsChart2.chartOptions"
              :series="diagram2"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

const $earningsStrokeColor2 = "#379683";
const $earningsStrokeColor3 = "#4cdb95";

import axios from "@axios";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    VueApexCharts,
  },
  data() {
    return {
      config: {
        campaign: "digirans/dashboard/campaign",
        advertiser: "digirans/dashboard/advertiser",
        influencerall: "digirans/dashboard/influencer/all",
        influencerweek: "digirans/dashboard/influencer/weekly",
        influencermonth: "digirans/dashboard/influencer/monthly",
        influencerage: "digirans/dashboard/influencer/age",
        influencergender: "digirans/dashboard/influencer/gender",
      },
      series: [
        {
          name: "Total Data",
          data: [],
        },
      ],
      diagramshow:false,
      diagramshow2:false,
      diagram2: [],
      totalCampaign: 0,
      totalAdvertiser:0,
      totalWeekly:{},
      totalInfluencer:0,
      totalMonthly:{},

      // chartOptions: {
      //   chart: {
      //     sparkline: { enabled: true },
      //     toolbar: { show: false },
      //     type: "bar",
      //   },
      //   grid: {
      //     show: true,
      //     padding: {
      //       left: 0,
      //       right: 0,
      //     },
      //   },

      //   states: {
      //     hover: {
      //       filter: "none",
      //     },
      //   },
      //   colors: [],
      //   plotOptions: {
      //     bar: {
      //       columnWidth: "45%",
      //       distributed: true,
      //       endingShape: "rounded",
      //     },
      //   },
      //   tooltip: {
      //     x: { show: true },
      //   },

      //   xaxis: {
      //     show: true,
      //     categories: [],
      //     position: "top",
      //     axisBorder: {
      //       show: false,
      //     },
      //     axisTicks: {
      //       show: true,
      //     },
      //     tooltip: {
      //       enabled: false,
      //     },
      //   },
      // },

      chartOptions: {
          chart: {
            type: 'bar',
            height: 450,
            toolbar: { show: false },
          },
          colors:[],
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '45%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            show:false,
            categories: [],
          },
          yaxis: {
            title: {
              text: 'Total Data'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val
              }
            }
          }
        },

      earningsChart2: {
        chartOptions: {
          chart: {
            type: "pie",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: true },
          comparedResult: [2, -3, 8],
          labels: ["Laki - Laki", "Perempuan"],
          stroke: { width: 0 },
          colors: ["#8ee4af", $themeColors.info],
          grid: {
            padding: {
              right: -25,
              // bottom: -8,
              left: -25,
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    };
  },

  methods: {
    getAge() {
      let _ = this;
      axios.get(_.config.influencerage).then((response) => {
        let res = response.data.data;
        // console.log(res[0]);
        let tamp1 = [];
        let tamp2 = [];
        for (var i = 0; i <= res.length; i++) {
          for(var key in res[i]) {
              // console.log(key);
              tamp1.push("Usia " + key);
              // console.log(res[i][key]);
              this.chartOptions.colors.push($themeColors.primary);
              tamp2.push(parseInt(res[i][key]));
          }
      }

        this.series[0].data = tamp2
        this.chartOptions.xaxis.categories = tamp1
        this.diagramshow = true
      });
    },

    getGender() {
      let _ = this;
      axios.get(_.config.influencergender).then((response) => {
        let res = response.data.data;
        for (let index = 0; index < res.length; index++) {
          this.diagram2.push(parseInt(res[index].total));
        }
        this.diagramshow2 = true
      });
    },
    getCampaign() {
      let _ = this;
      axios.get(_.config.campaign).then((response) => {
        _.totalCampaign = response.data.data[0].total;
      });
    },
    getAdvertiser() {
      let _ = this;
      axios.get(_.config.advertiser).then((response) => {
        _.totalAdvertiser = response.data.data[0].total;
      });
    },
    getInfluencerAll() {
      let _ = this;
      axios.get(_.config.influencerall).then((response) => {
        _.totalInfluencer = response.data.data[0].total;
      });
    },
    getInfluencerWeekly() {
      let _ = this;
      axios.get(_.config.influencerweek).then((response) => {
        _.totalWeekly = response.data.data;
      });
    },
    getInfluencerMonthly() {
      let _ = this;
      axios.get(_.config.influencermonth).then((response) => {
        _.totalMonthly = response.data.data;
      });
    },
    
  },
  beforeMount() {
    let _ = this
    _.getAge()
    _.getGender()
    _.getCampaign()
    _.getAdvertiser()
    _.getInfluencerAll()
    _.getInfluencerWeekly()
    _.getInfluencerMonthly()
    // let series1 = [80, 80, 80, 80, 10, 67, 78, 98, 90];
    // let series2 = [50, 90];
    // let tamp1 = [];
    // let tamp2 = [];

    // for (let index = 0; index < series1.length; index++) {
    //   tamp1.push("Usia " + series1[index]);
    //   this.chartOptions.colors.push($themeColors.primary);
    //   tamp2.push(series1[index]);
    // }
    // console.log(this.series);
    // for (let index = 0; index < series2.length; index++) {
    //   this.diagram2.push(series2[index]);
    // }
    // this.series[0].data = tamp2;
    // this.chartOptions.xaxis.categories = tamp1;
    this.$forceUpdate();
    // console.log(this.series[0].data);
  


  },
};
</script>
