<template>
  <b-row>
    <b-col cols="12">
      <table-digirans
        :tableTitle="this.$route.meta.title"
        apiUrl="company-name"
        apiBase="digirans/company-type-names"
        creates="true"
        updates="true"
        deletes="true"
        shows="false"
        search="true"
        verifiers="false"
        confirmApproves="false"
        showandhide="false"
        buttonTitle="Nama Usaha"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-digirans>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import { BRow, BCol } from "bootstrap-vue";
import TableDigirans from "@/views/table/bs-table/TableDigirans";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";

export default {
  name: "UsersList",
  components: {
    BRow,
    BCol,
    TableDigirans,
  },
  data() {
    return {
      config: {
        api: "digirans/company-type-names",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID" },
        { key: "name", label: "Nama Usaha", sortable: true },
        { key: "companyType", label: "Jenis Usaha", sortable: true },
        { key: "Aksi", thClass: "width160px" },
      ],
      items: [],
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      console.log(ctx);
      const _ = this;
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? "ASC" : "DESC";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      console.log(filter);
      axios
        .get(
          _.config.api +
            "?size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&sortBy=" +
            sortBy +
            "&orderBy=" +
            orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          // _.perPage = consume.perPage
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      console.log(perPage, currentPage, "log");
      let a = currentPage;

      let _ = this;
      axios
        .get(_.config.api + "?size=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          _.perPage = 10;
          // _.perPage = consume.perPage
          _.items = consume.data;

          console.log(_.totalRows, _.currentPage, _.perPage, "ininini");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>