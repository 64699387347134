<template>
  <b-row>
    
    <b-col cols="12">
      <div class="card p-1">
        <h4>{{$route.meta.title}}</h4>
      </div>  
      
      <b-tabs pills class="card"> 
        <!-- <h4 class="ml-1 mt-1">{{$route.meta.title}}</h4>   -->
        <b-tab title="Semua" @click="changeUrl(1)" active>
          <table-digirans-specified
            v-if="table_active == 1"
            apiUrl="termin-payments"
            apiBase="digirans/termin-payments"
            search="true"
            approving="false"
            shows="false"
            dollar="false"
            show_termin="true"
            approve_termin="false"
            edit_termin="true"
            buttonTitle="Termin Payment"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template v-slot:filter>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="filter()"
              >
                Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1"
                @click="reseting_filter()"
              >
                Reset Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="Exporting()"
              >
                Export Excel
              </b-button>
            </template>
          </table-digirans-specified>
        </b-tab>
        <b-tab title="Request Termin" @click="changeUrl(2)" :active="act==1">
          <table-digirans-specified
            v-if="table_active == 2"
            apiUrl="termin-payments"
            apiBase="digirans/termin-payments"
            search="true"
            approving="false"
            shows="false"
            show_termin="true"
            approve_termin="true"
            edit_termin="true"
            buttonTitle="Termin Payment"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields2"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template v-slot:filter>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="filter()"
              >
                Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1"
                @click="reseting_filter()"
              >
                Reset Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="Exporting()"
              >
                Export Excel
              </b-button>
            </template>
          </table-digirans-specified>
        </b-tab>

        <b-tab title="On Process" @click="changeUrl(3)">
          <table-digirans-specified
            v-if="table_active == 3"
            apiUrl="termin-payments"
            apiBase="digirans/termin-payments"
            search="true"
            approving="false"
            shows="false"
            dollar="false"
            show_termin="true"
            approve_termin="false"
            edit_termin="true"
            buttonTitle="Termin Payment"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fieldsonProcess"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template v-slot:filter>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="filter()"
              >
                Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1"
                @click="reseting_filter()"
              >
                Reset Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="Exporting()"
              >
                Export Excel
              </b-button>
            </template>
          </table-digirans-specified>
        </b-tab>

        <b-tab title="Completed" @click="changeUrl(4)">
          <table-digirans-specified
            v-if="table_active == 4"
            apiUrl="termin-payments"
            apiBase="digirans/termin-payments"
            search="true"
            approving="false"
            shows="false"
            dollar="false"
            show_termin="true"
            approve_termin="false"
            edit_termin="false"
            buttonTitle="Termin Payment"
            :perPage="perPage"
            :currentPage="currentPage"
            :totalRows="totalRows"
            :fields="fields"
            :items="getItems"
            @changePerPage="changePerPage"
            @changeCurrentPage="changeCurrentPage"
          >
            <template v-slot:filter>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="filter()"
              >
                Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1"
                @click="reseting_filter()"
              >
                Reset Filter
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class=""
                @click="Exporting()"
              >
                Export Excel
              </b-button>
            </template>
          </table-digirans-specified>
        </b-tab>
      </b-tabs>
    </b-col>
    <b-modal
      ref="my-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Filter Termin"
      size="lg"
    >
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-group label="Nama Bisnis" label-for="mc-nama-bisnis">
            <b-form-input
              id="mc-nama-bisnis"
              placeholder="Nama Bisnis"
              v-model="filtering.companyName"
            />
          </b-form-group>
        </b-col>
         <b-col md="6">
          <b-form-group label="Nama Pengguna" label-for="mc-nama-pengguna">
            <b-form-input
              id="mc-nama-pengguna"
              placeholder="Nama Pengguna"
              v-model="filtering.fullName"
            />
          </b-form-group>
        </b-col>
        <!-- :disabled-date="notAfterToday" -->
        <b-col md="6">
          <b-form-group label="Tanggal Invoice" label-for="mc-request">
            <date-picker
              :firstDayOfWeek="1"
              v-model="filtering.createdAt"
              name="tanggal_invoice"
              :lang="lang"
              :format="formats"
            ></date-picker>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group label="Batas Akhir Pembayaran" label-for="mc-request">
            <date-picker
              :firstDayOfWeek="1"
              v-model="filtering.endDate"
              name="batas_akhir_pembayaran"
              :lang="lang"
              :format="formats"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Status" label-for="mc-status">
            <select class="form-control" v-model="filtering.status">
              <option v-for="(i, index) in options" :key="index" :value="i.value">
                {{ i.name }}
              </option>
            </select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        variant="primary"
        @click="filters()"
        class="mr-1 float-right"
      >
        Filter
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        type="reset"
        class="mr-1 float-right"
        variant="outline-secondary"
        @click="hideModal()"
      >
        Batal
      </b-button>
    </b-modal>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
.nav-pills {
  margin-left:16px;
  margin-top: 16px;
}
</style>

<script>
import { BRow, BCol, BButton, BFormGroup, BFormInput, BTab,
  BTabs} from "bootstrap-vue";
import TableDigiransSpecified from "@/views/table/bs-table/TableDigiransSpecified";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    TableDigiransSpecified,
    BButton,
    BFormGroup,
    BFormInput,
    DatePicker,
    BTab,
    BTabs,
  },
   directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        api: "digirans/termin-payments?status=all",
        export: "digirans/termin-payments/export?status=all",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 100,
      fields: [
        "no",
        { key: "id", label: "ID"},
        { key: "advertiser.companyName", label: "Nama Bisnis", sortable: true },
        { key: "advertiser.fullName", label: "Nama Pengguna", sortable: true },
        { key: "campaign.name", label: "Nama Campaign", sortable: true},
        { key: "createdAt_cast", label: "Tanggal Invoice", sortable: true },
        { key: "endDate_cast", label: "Batas Akhir Pembayaran", sortable: true },
        { key: "amount", label: "Waktu Termin", sortable: true },
        { key: "status", label: "Status" , sortable: true},
        // { key: "file", label: "File" },
        { key: "Aksi", thClass: "width160px" },
      ],

      fieldsonProcess: [
        "no",
        { key: "id", label: "ID" },
        { key: "advertiser.companyName", label: "Nama Bisnis", sortable: true},
        { key: "advertiser.fullName", label: "Nama Pengguna", sortable: true},
        { key: "campaign.name", label: "Nama Campaign"},
        { key: "createdAt_cast", label: "Tanggal Invoice", sortable: true},
        { key: "endDate_cast", label: "Batas Akhir Pembayaran", sortable: true},
        { key: "progress", label: "Waktu Termin", sortable: true},
        { key: "status", label: "Status", sortable: true},
        // { key: "file", label: "File" },
        { key: "Aksi", thClass: "width160px" },
      ],

      fields2: [
        "no",
        { key: "id", label: "ID" },
        { key: "advertiser.companyName", label: "Nama Bisnis" ,sortable: true},
        { key: "advertiser.fullName", label: "Nama Pengguna" ,sortable: true},
        { key: "campaign.name", label: "Nama Campaign"},
        { key: "createdAt_cast", label: "Tanggal Invoice" ,sortable: true},
        { key: "endDate_cast", label: "Batas Akhir Pembayaran" ,sortable: true},
        { key: "amount", label: "Waktu Termin" ,sortable: true},
        { key: "attachment", label: "File"},
        { key: "status", label: "Status" ,sortable: true},
        // { key: "file", label: "File" },
        { key: "Aksi", thClass: "width160px" },
      ],
      items: [],
      filtering: {
        companyName: "",
        fullName: "",
        status: "",
        createdAt: "",
        endDate:""
      },
      options: [
         {
          id: 1,
          name: "Waiting For Confirmation",
          value: "waiting-for-confirmation",
        },
        {
          id: 2,
          name: "Reviewed",
          value: "reviewed",
        },
        {
          id: 3,
          name: "Waiting For Approval",
          value: "waiting-for-approval",
        },
        {
          id: 4,
          name: "Completed",
          value: "completed",
        },
        {
          id: 5,
          name: "On Process",
          value: "on-process",
        },
        {
          id: 6,
          name: "Rejected",
          value: "rejected",
        }
      ],
      addctx: null,
      addcall: null,
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
      table_active : 1,
      filtering_export : {},
      act : null,
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
    setTimeout(() => {
      if (localStorage.getItem('terminset') == 'true') {
        this.changeUrl(2)
        this.act = 1
        localStorage.removeItem('terminset')
      }  
    }, 3);
  },
  methods: {
    changeUrl(val) {
      this.table_active = val;
      if (val == 1) {
        this.currentPage = 1
        console.log(val);
        this.config.api = "digirans/termin-payments?status=all";
        this.config.export = "digirans/termin-payments/export?status=all";
        this.getItems(this.addctx, this.addcall);
      } else if (val == 2) {
        this.currentPage = 1
        console.log(val);
        this.config.api = "digirans/termin-payments?status=request";
        this.config.export = "digirans/termin-payments/export?status=request";
        this.getItems(this.addctx, this.addcall);
      }  else if (val == 3) {
        console.log(val);
        this.currentPage = 1
        this.config.api = "digirans/termin-payments?status=process";
        this.config.export = "digirans/termin-payments/export?status=process";
        this.getItems(this.addctx, this.addcall);
      } else {
        console.log(val);
        this.currentPage = 1
        this.config.api = "digirans/termin-payments?status=completed";
        this.config.export = "digirans/termin-payments/export?status=completed";
        this.getItems(this.addctx, this.addcall);
      }
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    filter() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.model = {};
    },
    Exporting() {
      let _ = this;
      let ctx = this.addctx
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let url = this.config.export + filter + "&filtering="+ JSON.stringify(this.filtering_export)
      axios.get(url, { responseType: "arraybuffer" }).then((response) => {
        if (response) {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "RequestWithdraw" + new Date();
          link.click();
        }
      });
    },
    getItems(ctx, callback) {
      console.log(ctx);
      const _ = this;
      this.addctx = ctx;
      this.addcall = callback;
      let filter = ctx.filter ? "&search=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? 'DESC' : 'ASC'
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length-1]
      if (sortBy.includes("_cast") == true) {
          sortBy = sortBy.split('_')
          sortBy = sortBy[0]
      }

      axios
        .get(
          _.config.api +
            "&size=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&filtering="+ JSON.stringify(this.filtering) +
            "&sortBy="+ sortBy + 
            "&orderBy="+ orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          // _.perPage = consume.perPage
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          // console.log(_.totalRows, _.currentPage, _.perPage, "ass");
          callback(consume.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    reseting_filter()
    {
      this.getItems(this.addctx, this.addcall);
        this.filtering = {
          companyName: "",
          fullName: "",
          status: "",
          createdAt: "",
          endDate:""
        };
       this.filtering_export = {
        companyName: "",
        fullName: "",
        status: "",
        createdAt: "",
        endDate:""
      };
    },
    filters() {
      this.getItems(this.addctx, this.addcall);
      this.$refs["my-modal"].hide();
      this.filtering_export = this.filtering
      
    },
    renderTable(perPage, currentPage) {
      // console.log(perPage, currentPage, "log");
      let a = currentPage;

      let _ = this;
      axios
        .get(_.config.api + "?size=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          _.perPage = 10;
          // _.perPage = consume.perPage
          _.items = consume.data;

          console.log(_.totalRows, _.currentPage, _.perPage, "ininini");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>