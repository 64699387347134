<template>
  <b-card :title="tableTitle">
    <div class="mb-2">
      <!-- filter -->

      <div class="d-flex justify-content-between flex-wrap">
        <div>
          <slot name="filter"></slot>
        </div>
        <!-- filter -->
        <b-form-group
          v-if="search == 'true'"
          label="Cari"
          label-cols-sm="2"
          label-align-sm="right"
          label-size=""
          label-for="filterInput"
          class="mb-0"
        >

          <b-input-group size="8">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="'Cari disini'"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      primary-key="email"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :sort-direction="sortDirection"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="fields"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tableDigiransSpecified"
    >
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(status_active)="data">
        {{
          data.item.account && data.item.account.verified == true
            ? "Verified"
            : "Not Verified"
        }}
      </template>

      <template #cell(status)="data">
        <span class="text-capitalize">{{ data.item.status }}</span>
      </template>

      <template #cell(attachment)="data">
        <!-- <span class="text-capitalize">{{ data.item.attachment }}</span> -->
        <b-link @click="DowmloadFile(data.item.advertiserAttachment)" v-if="data.item.advertiserAttachment">
          <span style="color:blue;"> {{ data.item.filenameAdvertiser }} </span>
        </b-link>
      </template>

      <!-- A virtual column -->
      <template #cell(Aksi)="data">
        <!-- <b-dropdown right text="Menu"> -->
        <!-- <b-dropdown-item
            v-if="verifiers == 'true'"
            @click="confirmVerified(data.item.accountID, data.item.account.verified)"
            >Verify</b-dropdown-item
          >
          <b-dropdown-item
            v-if="shows == 'true'"
            @click="gotoShowPage(data.item.id)"
            >Show</b-dropdown-item
          >
          <b-dropdown-item
            v-if="updates == 'true'"
            @click="gotoEditPage(data.item.id)"
            >Edit</b-dropdown-item
          > -->
        <!-- <b-dropdown-divider v-if="!data.item.is_admin"></b-dropdown-divider> -->
        <!-- <b-dropdown-item
            v-if="deletes == 'true'"
            variant="danger"
            @click="confirmText(data.item.id)"
            >Delete</b-dropdown-item
          >

        <b-dropdown-item
            v-if="confirmApproves == 'true' && data.item.broadcastStatus == false"
            variant="danger"
            @click="confirmApprove(data.item.id)"
            >Approve</b-dropdown-item
          >
        </b-dropdown> -->

        <div style="min-width: 200px">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            v-b-popover.hover.bottom="'Setujui'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="approving == 'true' && data.item.status == 'pending'"
            @click="confirmText(data.item.id)"
          >
            <span style="font-size: 11px">Approve</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            v-b-popover.hover.bottom="'Lihat'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="shows == 'true'"
            @click="gotoShowPage(data.item.id)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            v-b-popover.hover.bottom="'Proses'"
            size="sm"
            class="btn-icon float-sm-left mr-1"
            v-if="dollar == 'true' && data.item.status == 'pending'"
            @click="gotoProcess(data.item.id)"
          >
            <feather-icon icon="DollarSignIcon" />
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="info"
              v-b-popover.hover.bottom="'Setujui Termin'"
              size="sm"
              class="btn-icon float-sm-left mr-1"
              v-if="approve_termin == 'true' && data.item.status == 'waiting-for-approval'"
              @click="ApproveTermin(data.item.id)"
              >
              <feather-icon icon="CheckIcon" />
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              v-b-popover.hover.bottom="'Tolak Penarikan'"
              size="sm"
              class="btn-icon float-sm-left mr-1"
              v-if="canceled == 'true' && data.item.status == 'pending'"
              @click="CanceledRequest(data.item.id)"
              >
              <feather-icon icon="XCircleIcon" />
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              v-b-popover.hover.bottom="'Lihat'"
              size="sm"
              class="btn-icon float-sm-left mr-1"
              v-if="show_termin == 'true'"
              @click="gotoShowTermin(data.item.id)"
              >
              <feather-icon icon="EyeIcon" />
          </b-button>

          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              v-b-popover.hover.bottom="'Edit'"
              size="sm"
              class="btn-icon float-sm-left mr-1"
              v-if="edit_termin == 'true' && data.item.status == 'waiting-for-confirmation'"
              @click="gotoEditPage(data.item.id)"
              >
              <feather-icon icon="EditIcon" />
          </b-button>

        </div>
      </template>
    </b-table>
    <span v-if="totalRows == 1" style="margin: 1rem"></span>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <!-- <b-form-group
            label="Per Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
        >
            <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            />
        </b-form-group> -->

      <b-form-group
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
            id="perPageSelect"
            v-model="onTablePerPage"
            @change="changePerPage()"
            size="sm"
            inline
            :options="pageOptions"
            /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="onTableCurrentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

    <b-modal
      ref="my-modal-process"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      size="xl"
      title="Proses Withdraw"
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(processing)">
          <b-row>
            <b-col md="3" class="mb-1"> Influencer </b-col>
            <b-col md="3" class="">
              : {{ detail.influencer.fullName }}
            </b-col>

            <b-col md="3" class="mb-1"> Tanggal Pembayaran </b-col>
            <b-col md="3" > 
                <ValidationProvider
                  name="Tanggal Pembayaran"
                  rules="required"
                  v-slot="{ errors }"
                >
                    <date-picker
                      :firstDayOfWeek="1"
                      v-model="model.paymentDate"
                      name="date_of_birth"
                      :lang="lang"
                      :format=formats
                      :disabled-date="notAfterToday"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  
                </ValidationProvider>
            </b-col>

            <b-col md="3" class="mb-1"> Saldo </b-col>
            <b-col md="3" class="mb-1">
              : {{ detail.influencer.balance | currency }}
            </b-col>

            <b-col md="3" class="mb-1"> Nama Bank </b-col>
            <b-col md="3" class="mb-1"> : {{ detail.bank }} </b-col>

            <b-col md="3" class="mb-1"> Tanggal Request </b-col>
            <b-col md="3" class="mb-1"> : {{ detail.createdAt_cast }} </b-col>

            <b-col md="3" class="mb-1"> No. Rekening </b-col>
            <b-col md="3" class="mb-1">
              : {{ detail.bankAccountNumber }}
            </b-col>

            <b-col md="3" class="mb-1"> Nominal Withdraw </b-col>
            <b-col md="3" class="mb-1"> : {{ detail.amount | currency}} </b-col>

            <b-col md="3" class="mb-1"> A.n Rekening </b-col>
            <b-col md="3" class="mb-1"> : {{ detail.name }} </b-col>

            <b-col md="3" class="mb-1"> Status Withdraw </b-col>
            <b-col md="3" class="mb-1">
              <ValidationProvider
                name="Status"
                rules="required"
                v-slot="{ errors }"
              >
                  <b-form-radio-group
                    v-model="model.status"
                    :options="verifiedOptions"
                    class=""
                    value-field="value"
                    text-field="text"
                  />
                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
              </ValidationProvider>
            </b-col>

            <b-col md="3" class="mb-1"> Nominal Pembayaran </b-col>
            <b-col md="3" class="mb-1"> 
              
              <ValidationProvider
                name="Nominal Pembayaran"
                rules="required|numeric"
                v-slot="{ errors }"
              >
                
                  <!-- <b-form-input
                    id="mc-firstname"
                    placeholder="Nominal Pembayaran"
                    v-model="model.paymentAmount"
                  /> -->
                  <vue-numeric class="form-control" v-bind:minus="false" v-bind:precision="2" currency="Rp." separator="." v-model="model.paymentAmount"></vue-numeric>
                  <p class="fs-6 text-danger">
                    {{ errors[0] }}
                  </p>
              </ValidationProvider>
            
             </b-col>

            <b-col md="12">
               <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class=""
                style="width:100%"
              >
                Bayar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-modal>

    <b-modal
      ref="my-modal-detail"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      size="lg"
      title="Detail Withdraw"
    >
      <b-row>
        <b-col md="3" class="mb-1"> Influencer </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.influencer.fullName }} </b-col>

        <b-col md="3" class="mb-1"> Tanggal Pembayaran </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.paymentDate_cast }} </b-col>

        <b-col md="3" class="mb-1"> Saldo </b-col>
        <b-col md="3" class="mb-1">
          : {{ detail.influencer.balance | currency}}
        </b-col>

        <b-col md="3" class="mb-1"> Nama Bank </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.bank }} </b-col>

        <b-col md="3" class="mb-1"> Tanggal Request </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.createdAt_cast }} </b-col>

        <b-col md="3" class="mb-1"> No. Rekening </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.bankAccountNumber }} </b-col>

        <b-col md="3" class="mb-1"> Nominal Withdraw </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.amount | currency}} </b-col>

        <b-col md="3" class="mb-1"> A.n Rekening </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.name }} </b-col>

        <b-col md="3" class="mb-1"> Status Withdraw </b-col>
        <b-col md="3" class="mb-1">
          : <span class="text-capitalize">{{ detail.status }}</span>
        </b-col>

        <b-col md="3" class="mb-1"> Nominal Pembayaran </b-col>
        <b-col md="3" class="mb-1"> : {{ detail.paymentAmount | currency }} </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>
<style>
.mx-datepicker {
  width: 100% !important;
  padding:0;
}
</style>

<script>
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BModal,
  VBModal,
  BForm,
  BFormRadioGroup,
  BFormTextarea,
  VBPopover,
  BFormCheckbox,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";

import Ripple from "vue-ripple-directive";
import { EventBus } from "@/eventBus.js";
import BCardCode from "@core/components/b-card-code";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import VueNumeric from 'vue-numeric'

export default {
  props: [
    "tableTitle",
    "apiUrl",
    "perPage",
    "currentPage",
    "items",
    "fields",
    "totalRows",
    "apiBase",
    "search",
    "buttonTitle",
    "approving",
    "shows",
    "dollar",
    "approve_termin",
    "show_termin",
    "edit_termin",
    "canceled"
  ],
  components: {
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    vSelect,
    BModal,
    BCardCode,
    BForm,
    BFormRadioGroup,
    BFormTextarea,
    BFormCheckbox,
    BRow,
    DatePicker,
    VueNumeric,
    BLink,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-popover": VBPopover,
  },
  data() {
    return {
      errors: {},
      onTablePerPage: this.$props.perPage,
      onTableCurrentPage: this.$props.currentPage,
      pageOptions: [1, 10, 25, 50, 100],
      filter: null,
      sortDirection: "asc",
      sortBy: "",
      sortDesc: false,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      verifiedOptions: [
        {
          value: "pending",
          text: "Unpaid",
        },
        {
          value: "success",
          text: "Paid",
        },
      ],
      /* eslint-disable global-require */
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      model: {},
      detail: {
        influencer: {},
      },
      id_process:null,
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
    return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    let _ = this;
    // Set the initial number of items
    EventBus.$on("changeValue", () => {
      _.$refs.tableDigiransSpecified.refresh();
    });
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },

    hideModal() {
      this.$refs["my-modal-detail"].hide();
      this.model = {};
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      console.log(filteredItems);
    },
    changePerPage() {
      console.log(this.onTablePerPage, "ini Ditable");
      this.$emit("changePerPage", this.onTablePerPage);
    },
    gotoEditPage(id) {
      let _ = this;
      console.log(_.$props.apiUrl);
      window.location = "/" + _.$props.apiUrl + "/" + id;
    },
    gotoShowPage(id) {
      let _ = this;
      // _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/show");

      axios
        .get("/digirans/withdraws/" + id)
        .then((response) => {
          this.detail = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      this.$refs["my-modal-detail"].show();
    },
    gotoProcess(id) {
      let _ = this;
      _.id_process = id
      // _.$router.push("/" + _.$props.apiUrl + "s/" + id + "/show");
      this.model.status = "pending"
      axios
        .get("/digirans/withdraws/" + id)
        .then((response) => {
          this.detail = response.data.data;
        })
        .catch((err) => {
          console.log(err);
        });

      this.$refs["my-modal-process"].show();
    },

    gotoShowTermin(id) {
      let _ = this;
      console.log( _.$props.apiUrl );
      _.$router.push("/" + _.$props.apiUrl + "/" + id + "/show");
    },

    processing()
    {
      let _ = this
      if (_.model.status != "success") {
       
        this.$swal({
          // title: 'Error!',
          text: 'Silahkan ubah status menjadi \'Paid\' ',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
      axios
        .put("/digirans/withdraws/" + _.id_process, _.model)
        .then((response) => {
          this.$refs["my-modal-process"].hide();
          this.model = {}
          this.$swal({
            icon: "success",
            title: "Pembayaran Sudah Diproses",
            text: response.data.data.messages,
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    gotoCreatePage() {
      let _ = this;
      _.$router.push("/" + _.$props.apiUrl + "s/add");
    },
    confirmText(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        text: "untuk setujui transaksi ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/" + this.$props.apiBase + "/approval/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Tersetujui",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableDigiransSpecified.refresh();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },

    CanceledRequest(id) {
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menolak request withdraw?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {

        console.log(this.$props.apiBase, "ini Api Base");


        // return

        if (result.value) {
          axios
            .put("digirans/withdraws/canceled/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Dibatalkan",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableDigiransSpecified.refresh();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    DowmloadFile(id)
    {
      console.log(id);

      window.open(id)
      // let url = "digirans/termin-payments/download/contract/" + id

      // axios.get(url).then((response) => {
      //   if (response) {
      //     // console.log(response.data);
      //     window.open(response.data)
      //     // let blob = new Blob([response.data], {
      //     //   type: "application/pdf",
      //     // });
      //     // let link = document.createElement("a");
      //     // link.href = window.URL.createObjectURL(blob);
      //     // link.download = "File Temin " + id + new Date();
      //     // link.click();
      //   }
      // });
    },
    ApproveTermin(id) {
      let _ = this
      this.$swal({
        title: "Apakah Anda Yakin",
        text: "untuk menyetujui termin?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .put("/" + this.$props.apiBase + "/approve/" + id)
            .then((response) => {
              this.$swal({
                icon: "success",
                title: "Berhasil Disetujui",
                text: response.data.data.messages,
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.$refs.tableDigiransSpecified.refresh();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
  watch: {
    onTableCurrentPage: function (newValue) {
      let _ = this;
      console.log(newValue, "newvalue");
      _.$emit("changeCurrentPage", newValue);
    },
    items: function (newValue) {
      console.log(newValue);
      _.totalRows = _.items.length;
    },
  },
};
</script>
