<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-height-fade
          dismissible
          v-model="showDismissibleAlert"
          fade
          class="mb-2"
          variant="danger"
          v-id="showDismissibleAlert == true"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  name="Nama Lengkap"
                  rules="required"
                  v-slot="{ errors }"
                  
                >
                  <b-form-group label="Nama Lengkap" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      placeholder="Masukkan Nama Lengkap"
                      v-model="model.fullName"
                      data-vv-as="First Name" 
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Alamat Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Alamat Email" label-for="mc-name">
                    <b-form-input
                      id="mc-email"
                      placeholder="Masukkan Alamat Email"
                      v-model="model.email"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Password"
                  :rules="
                    $route.meta.action == 'store' ? 'required|min:6' : 'min:6'
                  "
                  vid="confirmation"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Password" label-for="mc-password">
                    <b-form-input
                      id="mc-password"
                      placeholder="Masukkan Password"
                      v-model="model.password"
                      type="password"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Konfirmasi Password"
                  :rules="
                    $route.meta.action == 'store' || model.password != null
                      ? 'required|min:6|confirmed:confirmation'
                      : 'min:6|confirmed:confirmation'
                  "
                  v-slot="{ errors }"
                >
                  <b-form-group
                    label="Konfirmasi Password"
                    label-for="mc-password-confirmation"
                  >
                    <b-form-input
                      id="mc-password-confirmation"
                      placeholder="Masukkan Konfirmasi Password"
                      v-model="model.confirmPass"
                      type="password"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Nomor Telepon"
                  :rules="{ regex:/^(\+62|62|0)8[1-9][0-9]{6,11}$/, required:true}"
                  v-slot="{ errors }"
                >
                  <b-form-group label="No Telepon" label-for="mc-no-telepon">
                    <b-form-input
                      id="mc-no-telepon"
                      placeholder="Masukkan Nomor Telepon"
                      v-model="model.phoneNumber"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  name="Role"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-form-group label="Role" label-for="mc-role">
                    <select class="form-control" v-model="model.roleID">
                      <option
                        v-for="(i, index) in roles"
                        :key="index"
                        :value="i.id"
                      >
                        {{ i.name }}
                      </option>
                    </select>

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'users' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BFormRadioGroup,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import { heightFade } from "@core/directives/animations";

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    BForm,
    BButton,
    BFormRadioGroup,
    vSelect,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BFormTextarea,
    BAlert,
  },
  directives: {
    Ripple,
    "height-fade": heightFade,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: 'digirans/user-managements',
        role: 'misc/types/roles',
        redirect: "users",
      },
      model: {},
      roles:[],
      messages: "",
    };
  },
  mounted() {
    let _ = this;
    _.getData();
    _.getRoles()
    console.log(_.$route.meta.action);
  },
  methods: {
    getRoles() {
        let _ = this
        axios
          .get(_.config.role)
          .then((response) => {
            let _ = this;
            _.roles = response.data.data;
          });
    },
    getData() {
      let _ = this;
      if (this.$route.meta.action == "edit") {
        axios
          .get(_.config.api + "/" + this.$route.params.id)
          .then((response) => {
            let _ = this;
            _.model = response.data.data;
            _.model.email = _.model.email
            _.model.fullName = _.model.user.fullName
            _.model.roleID = _.model.user.roleID
            _.model.password = null
            _.model.confirmPass = null
          });
      }
    },
    save() {
      let _ = this;
      if (this.$route.meta.action == "store") {
        axios
          .post(_.config.api, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Create Success",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            // console.log(e.response.data.message.errors[0].msg);
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === 'object' ) {
                vm.messages = e.response.data.message.errors[0].msg  
            } else {
                vm.messages = e.response.data.message; 
            }
          });
      } else {
        _.model.id = _.$route.params.id;
        axios
          .put(_.config.api + "/" + _.$route.params.id, _.model)
          .then((res) => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: "success",
                title: "Update User Berhasil",
                text: res.data.message,
              },
            });
          })
          .catch((e) => {
            let vm = this;
            vm.showDismissibleAlert = true;
            if (typeof e.response.data.message === 'object' ) {
                vm.messages = e.response.data.message.errors[0].msg  
            } else {
                vm.messages = e.response.data.message; 
            }
          });
      }
    },
  },
};
</script>
